import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import Alert from 'react-s-alert'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { space as styledSpace } from 'styled-system'
import moment from 'moment-timezone'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import Modal from '@material-ui/core/Modal'
import { useDropzone } from 'react-dropzone'
import ClipLoader from 'react-spinners/ClipLoader'
import SyncLoader from 'react-spinners/SyncLoader'
import { COLOR_CONSTANTS, radius, space, fontSizes } from 'theme'
import {
  ACCESS_MANAGE,
  CALENDAR_OPTIONS,
  COMMENT_TYPE_PUBLICATION,
  DEFAULT_TIME_ZONE,
  ERROR_MESSAGE,
  FEATURE_ADD_TO_QUEUE,
  FEATURE_QUEUE_LABELS,
  PERMISSION_PUBLISH,
  PROFILE_STATUSES,
  VIDEO,
  USER_TYPES,
  CUSTOM,
} from 'consts'
import { pxToRem, stripHtml, useAsyncState } from 'helpers'
import withConfirm from 'utils/withConfirm'
import { checkIfPlanHasFeatureEnabled, hasEntitiesAccess } from 'utils/feature'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import Radio from 'components/atoms/Radio'
import Image from 'components/atoms/Image'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import DropdownMenu from 'components/molecules/DropdownMenu'
import Icon from 'components/atoms/Icon'
import Link from 'components/atoms/Link'
import Badge from 'components/atoms/Badge'
import Tooltip from 'components/molecules/Tooltip'
import Divider from 'components/atoms/Divider'
import DropDown from 'shared/DropDown'
import { handleMediaFileChange } from 'routes/Media/helper'
import { getProfileDisplay } from 'routes/Settings/Profiles/helpers'
import DatePicker from './components/DatePicker'
import SpecialMediaProcessingDataComponent from '../SpecialMediaProcessingDataComponent'
import EntitySelectorWrapper from '../EntitySelectorWrapper'
import SocialProfileImage from '../SocialProfileImage'
import PostCreationComponent from '../PostCreationComponent'
import AssignModal from '../AssignModal'
import RejectApproveNotesModal from '../RejectApproveNotesModal'
import SetTimezoneModal from '../SetTimezoneModal'
import {
  APPROVED,
  PUBLISHED,
  PROCESSING,
  FAILED,
  ADVOCACY,
  BLOCKED_PUBLISHING_SOCIAL_PROFILES,
  CALENDAR_POST_PREVIEW_CONDENSED,
  CUSTOM_TYPE,
  DATE_FORMAT,
  DEFAULT_QUEUE_LABEL,
  DRAFT,
  LOCAL_STORAGE_PUBLISH_POST_MODAL,
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  POST_DOCUMENT,
  POST_IMAGE,
  POST_LINK,
  POST_VIDEO,
  POST_MIXED_MEDIA,
  QUEUE_LAST,
  QUEUE_NEXT,
  REJECTED,
  REVIEW,
  ROUTE_QUEUE,
  SCHEDULE_TYPE_DEFAULT,
  SCHEDULE_TYPE_DUPLICATE,
  SCHEDULE_TYPE_NEW,
  PUBLISH_NOW,
  PUBLISH_LATER,
  PUBLISH_DRAFT,
  PUBLISH_QUEUE,
  PUBLISH_SAVE_CHANGES,
  PUBLISH_WHEN_READY,
  POST_TYPE_HOLIDAY,
  POST_TYPE_SOCIAL_POST,
  POST_TYPE_EXTERNAL_CALENDAR_EVENT,
  POST_TYPE_NOTE,
  REMINDER_SENT,
} from '../../consts'
import {
  formatTimezoneToGMT,
  getApproversData,
  getPublicationDataFromLocalStorage,
  getSelectedProfileGroupsAndTimezone,
  mentionsTransformer,
  purifyingPost,
  setPublicationDataToLocalStorageDebounce,
  collectPostAttachments,
  collectAdvocacyDataBeforePublishing,
} from '../../helpers'
import ProfileItemTooltip from '../ProfileItemTooltip'
import CalendarItemTooltip from '../CalendarItemTooltip'
import { groupCalendarEvents, renderEventContent } from '../../calendarRenderHelper'
import SelectedProfilesSlider from '../SelectedProfilesSlider'
import DeletePostModal from '../DeletePostModal'
import SliderPostsPreview from '../SliderPostsPreview'
import SliderCommentsPreview from '../SliderCommentsPreview'
import VideoProcessingWarningModal from '../VideoProcessingWarningModal'
import HistoryPostModal from '../HistoryPostModal'
import { PostCreationProvider } from '../../contexts/PostCreationContext'
import SendForApprovalProfileGroupsModal from './components/SendForApprovalProfileGroupsModal'

const StyledDialogOverlay = styled(Modal)`
  &&& {
    background-color: ${({ theme }) => transparentize(0.2, theme.colors.background_modal_overlay)};
    z-index: 2147483001 !important;
    > * {
      &:first-child {
        background: none !important;
      }
    }
  }
`

const StyledDialogContent = styled(Flex)`
  &&& {
    background-color: ${({ theme }) => theme.colors.background_card};
    position: relative;
    max-width: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  background: ${({ $isAdvocacy, theme }) =>
    $isAdvocacy
      ? 'linear-gradient(90deg, rgba(83, 158, 255, 0.1) 0%, rgba(97, 0, 255, 0.1) 100%)'
      : theme.colors.background_modal_header};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${({ theme }) => theme.colors.background_card};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const CalendarColumnWrapper = styled(Flex)`
  border-left: 1px solid ${({ theme }) => theme.colors.border_color};
`

const StyledImage = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 14px;
  height: 14px;
  padding: 0;
  border: none;
  outline: none;
`

const StyledFlex = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border_color};
`

const StyledGroupEqualIcon = styled(Icon.GroupEqual)`
  fill: ${({ theme }) => theme.colors.icon_color_gray};
`

const MessageWrapper = styled(Flex)`
  background-color: ${({ theme }) => transparentize(0.9, theme.colors.error)};
  border-radius: ${radius.l};
`

const StyledScheduleWrapper = styled(Flex)`
  &:hover {
    .schedule-button {
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }

    .schedule-button-divider {
      border-top: 1px solid ${({ theme }) => theme.colors.primary};
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
    }
  }
`

const StyledScheduleDropDownButton = styled(Button.Gradient)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none !important;
  min-width: 32px;
  &:hover {
    border: none;
  }
`

const StyledScheduleButton = styled(ButtonWithLoading)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none !important;
  background: linear-gradient(90deg, #2482fd 0%, #0b60da 100%);
  &:hover {
    border: none;
  }
`

const StyledIconArrowDropdown = styled(Icon.ArrowDropdown)`
  fill: ${({ theme }) => theme.colors.white};
  transform: rotate(180deg);
`

const StyledRoundedButton = styled(Button.Gray)`
  min-width: auto;
  padding: 0;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: ${radius.pill};
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};
  }
`

const StyledFilesWrapper = styled(Flex)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: rgba(39, 40, 49, 0.3);
  z-index: 2147483002;
  transition: border 0.24s ease-in-out;
  border: 2px dashed ${({ theme }) => theme.colors.white};
  visibility: hidden;
  ${({ show }) => show && `visibility: visible;`}
`

const StyledQueueText = styled(Text)`
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
  }
`

const StyledDateNavigationButton = styled(Button.Gray)`
  min-width: auto;
  padding: 0 ${space.m};
  width: 34px;
  border: none;
  background-color: transparent;
  border-radius: ${radius.l};

  svg {
    min-width: 16px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};

    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

const VistaSocialDashboardArrowRight = styled(Icon.VistaSocialDashboardArrow)`
  transform: rotate(-180deg);
`

const StyledCommentIconWrapper = styled(Flex)`
  cursor: pointer;
  border-radius: ${radius.pill};
  box-shadow: 0 0 6px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};
  }
`

const StyledCommentCounterWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ThreeDotsWrapper = styled(Flex)`
  background: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: ${radius.pill};
  box-shadow: 0 0 6px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  &:hover {
    background-color: ${({ theme }) => theme.colors.icon_background_hover};
  }
`

const ThreeDots = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 14px;
  height: 14px;
`

const StyledScheduleIndividuallyText = styled(Text)`
  cursor: pointer;
  font-size: ${fontSizes.xs};
  color: ${({ theme }) => theme.colors.primary};
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background: ${({ theme }) => theme.colors.background_loading_progress};
`

const PUBLISHING_TIME = [
  { name: 'Save draft', id: PUBLISH_DRAFT },
  { name: 'Add to queue', id: PUBLISH_QUEUE },
  { name: 'Schedule', id: PUBLISH_LATER },
  { name: 'Publish now', id: PUBLISH_NOW },
]

const QUEUE_TIME = [
  { name: 'Queue next', id: QUEUE_NEXT },
  { name: 'Queue last', id: QUEUE_LAST },
]

const { LOADING_BUTTON_APPROVE, LOADING_BUTTON_REJECT, LOADING_BUTTON_SAVE, LOADING_BUTTON_SUBMIT } = {
  LOADING_BUTTON_APPROVE: 'approve',
  LOADING_BUTTON_REJECT: 'reject',
  LOADING_BUTTON_SAVE: 'save',
  LOADING_BUTTON_SUBMIT: 'submit',
}

const DAY_MAX_EVENT_ROWS = 5

const DEFAULT_MENU_HEIGHT = '34px'

const DEFAULT_SLIDER_OPTIONS = {
  isOpen: false,
  data: null,
}

const DEFAULT_VIDEO_PROCESSING_MODAL_OPTIONS = {
  isOpen: false,
  data: [],
}

const DEFAULT_SEND_FOR_APPROVAL_PROFILE_GROUPS_MODAL = {
  isOpen: false,
  selectedEntities: [],
  callbackFunc: () => {},
}

const DEFAULT_REJECT_APPROVE_NOTES_MODAL_OPTIONS = {
  isOpen: false,
  type: null,
}

const PublishModal = ({
  user,
  isOpen,
  handleDismiss,
  entities,
  handleSubmitPost,
  isPostSubmitting,
  loadingButton,
  selectedCalendarDate,
  getCalendarEvents,
  confirm,
  dataForCustomizations,
  calendarPostPreview: CALENDAR_POST_PREVIEW,
  calendarFirstDay: CALENDAR_WEEK_DAY_START,
  handleDeletePost,
  handleApproveRejectReminderPost,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const postCreationComponentRef = useRef(null)
  const calendarRef = useRef(null)
  const specialMediaProcessingDataComponentRef = useRef(null)

  const [calendarEvents, setCalendarEvents] = useState([])
  const [calendarEventForPublish, setCalendarEventsForPublish] = useState([])
  const [step, setStep] = useState(1)
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const [selectedProfilesForEntitySelector, setSelectedProfilesForEntitySelector] = useState([])
  const [selectedEntities, setSelectedEntities] = useState([])
  const [selectedTimezone, setSelectedTimezone] = useState(DEFAULT_TIME_ZONE)
  const [whenToPublish, setWhenToPublish] = useState(PUBLISH_NOW)
  const [whenToQueue, setWhenToQueue] = useState(QUEUE_LAST)
  const [savedPost, setSavedPost] = useState({})
  const [entityPublishValues, setEntityPublishValues] = useState([])
  const [entityPublishValuesGrouped, setEntityPublishValuesGrouped] = useState({
    isMultipleEntityGroups: false,
    isTogether: false,
    profiles: [],
  })
  const [isGettingQueueData, setIsGettingQueueData] = useState(false)
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false)
  const [rejectApproveNotesModalOptions, setRejectApproveNotesModalOptions] = useState({
    ...DEFAULT_REJECT_APPROVE_NOTES_MODAL_OPTIONS,
  })
  const [approversData, setApproversData] = useState({})
  const [isSetTimezoneModalOpen, setIsSetTimezoneModalOpen] = useState(false)
  const [isDeletePostModalOpen, setIsDeletePostModalOpen] = useState(false)
  const [isStepClicked, setIsStepClicked] = useState(false)
  const [checkForErrors, setCheckForErrors] = useAsyncState(false)
  const [entitySelectorKey, setEntitySelectorKey] = useState(moment().valueOf())
  const [featuresEnabled, setFeaturesEnabled] = useState({
    [FEATURE_ADD_TO_QUEUE]: { enabled: true },
    [FEATURE_QUEUE_LABELS]: { enabled: true },
  })
  const [dataToLocalStorageOptions, setDataToLocalStorageOptions] = useState({
    canSave: false,
    lastTimeSaved: 0,
    lastTimeSavedMessage: '',
  })
  const [postCreationComponentKey, setPostCreationComponentKey] = useState(new Date().getTime())
  const [uploadingMediasAmount, setUploadingMediasAmount] = useState(0)
  const [sliderPostsPreviewData, setSliderPostsPreviewData] = useState(null)
  const [usedQueueLabels, setUsedQueueLabels] = useState([])
  const [selectedQueueLabel, setSelectedQueueLabel] = useState(null)
  const [calendarOptions, setCalendarOptions] = useState({ ...CALENDAR_OPTIONS })
  const [sliderOptions, setSliderOptions] = useState({ ...DEFAULT_SLIDER_OPTIONS })
  const [isHistoryPostModalOpen, setIsHistoryPostModalOpen] = useState(false)
  const [videoProcessingWarningModalOptions, setVideoProcessingWarningModalOptions] = useState({
    ...DEFAULT_VIDEO_PROCESSING_MODAL_OPTIONS,
  })
  const [sendForApprovalProfileGroupsModal, setSendForApprovalProfileGroupsModal] = useState({
    ...DEFAULT_SEND_FOR_APPROVAL_PROFILE_GROUPS_MODAL,
  })
  const [clickedNextStepFromVideoProcessingWarningModal, setClickedNextStepFromVideoProcessingWarningModal] = useState(
    false
  )
  const [postHasBeenUpdated, setPostHasBeenUpdated] = useState(false)
  const [postCreationComponentDataIsLoading, setPostCreationComponentDataIsLoading] = useState(true)

  // TODO:: change review to workflow
  const { review, comments = 0, workflow = [], post_creator_user_gid, status } = savedPost
  const { reviewer: { label: reviewerName = '' } = {} } = review || {}

  const hasWorkflow = workflow && workflow.length !== 0

  const isAdvocacy = !!(props.postForEdit && (props.postForEdit.advocacy_gid || props.postForEdit.status === ADVOCACY))

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0 && uploadingMediasAmount === 0) {
      handleMediaFileChange({
        acceptedFiles,
        setUploadingMedias: (medias) => {
          postCreationComponentRef.current.setUploadingMedias(medias)
        },
        setUploadingMediasAmount,
      })
    }
  }, [])

  const { getRootProps, isDragActive } = useDropzone({ onDrop })

  useEffect(() => {
    if (isOpen) {
      setFeaturesEnabled({
        ...checkIfPlanHasFeatureEnabled({ user, features: [FEATURE_ADD_TO_QUEUE, FEATURE_QUEUE_LABELS] }),
      })
      if (props.postForEdit && (props.postForEdit.publication_group_id || props.postForEdit.isFromFindContentModal)) {
        setSavedPost({ ...JSON.parse(JSON.stringify(props.postForEdit)) })
        if (props.postForEdit.draft) {
          setWhenToPublish(PUBLISH_DRAFT)
        } else if (props.postForEdit.status === REVIEW || props.postForEdit.publication_group_id) {
          setWhenToPublish(PUBLISH_LATER)
        } else if (selectedCalendarDate) {
          setWhenToPublish(PUBLISH_LATER)
        } else {
          setWhenToPublish(PUBLISH_NOW)
        }
      } else {
        setSavedPost({})
        if (selectedCalendarDate) {
          setWhenToPublish(PUBLISH_LATER)
        } else {
          setWhenToPublish(PUBLISH_NOW)
        }
      }
      setEntityPublishValues([])
      setCalendarEventsForPublish([])
      setStep(1)

      const profilesWithAccess = props.selectedProfiles.filter(
        ({ entityId }) =>
          !hasEntitiesAccess({
            user,
            permission: PERMISSION_PUBLISH,
            entity_gid: entityId,
            accessLevels: [ACCESS_MANAGE],
          })
      )

      setSelectedProfilesForEntitySelector(profilesWithAccess)
      setSelectedProfiles(profilesWithAccess.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i))
      setSelectedEntities([...props.selectedEntities])

      if (!props.postForEdit) {
        getPublicationDataFromLocalStorage({
          key: LOCAL_STORAGE_PUBLISH_POST_MODAL,
          message: `You have an auto saved post. Do you want to load it?`,
          confirm,
          setData: (post) => {
            if (post.link) {
              post.link.needsUpload = true
            }

            if (post.postVideos) {
              post.postVideos.forEach((media) => {
                delete media.s3_urls
              })
            }

            if (post.postMixedMedias) {
              post.postMixedMedias.forEach((media) => {
                delete media.s3_urls
              })
            }

            if (post.customizations) {
              for (const network in post.customizations) {
                if (post.customizations.hasOwnProperty(network) && post.customizations[network]) {
                  for (const profile_gid in post.customizations[network]) {
                    if (
                      post.customizations[network].hasOwnProperty(profile_gid) &&
                      post.customizations[network][profile_gid]
                    ) {
                      if (post.customizations[network][profile_gid].postVideos) {
                        post.customizations[network][profile_gid].postVideos.forEach((media) => {
                          delete media.s3_urls
                        })
                      }

                      if (post.customizations[network][profile_gid].postMixedMedias) {
                        post.customizations[network][profile_gid].postMixedMedias.forEach((media) => {
                          delete media.s3_urls
                        })
                      }
                    }
                  }
                }
              }
            }

            setSavedPost({ ...post })
            setPostCreationComponentKey(new Date().getTime())
          },
          setDataToLocalStorageOptions,
        })
      } else {
        setDataToLocalStorageOptions({ ...dataToLocalStorageOptions, canSave: true })
      }
    }
  }, [isOpen])

  useEffect(() => {
    const { selectedTimezone } = getSelectedProfileGroupsAndTimezone({
      user,
      selectedProfiles: selectedProfilesForEntitySelector,
      selectedEntities,
    })

    setSelectedTimezone(selectedTimezone)
  }, [selectedEntities, selectedProfilesForEntitySelector])

  useEffect(() => {
    if (selectedQueueLabel && step === 2) {
      // eslint-disable-next-line no-use-before-define
      getQueueData({})
    }
  }, [selectedQueueLabel])

  useEffect(() => {
    if (clickedNextStepFromVideoProcessingWarningModal) {
      setVideoProcessingWarningModalOptions({ ...DEFAULT_VIDEO_PROCESSING_MODAL_OPTIONS })
      // eslint-disable-next-line no-use-before-define
      handleClickNextStep()
    }
  }, [clickedNextStepFromVideoProcessingWarningModal])

  const onChangeUpdatePost = () => {
    if (dataToLocalStorageOptions.canSave && postCreationComponentRef && postCreationComponentRef.current) {
      const { post } = postCreationComponentRef.current.handleReturnPost()
      const hasChangedByUser = postCreationComponentRef.current.handleHasPostChangedByUser()

      if (hasChangedByUser) {
        if (!postHasBeenUpdated) {
          setPostHasBeenUpdated(true)
        }

        setPublicationDataToLocalStorageDebounce({
          key: LOCAL_STORAGE_PUBLISH_POST_MODAL,
          data: post,
          dataToLocalStorageOptions,
          setDataToLocalStorageOptions,
        })
      }
    }
  }

  const handleSaveSelectedProfiles = async ({ data }) => {
    setSelectedProfilesForEntitySelector([...data])
    setSelectedProfiles(data.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i))
  }

  const handleSaveSelectedEntities = async ({ data }) => {
    setSelectedEntities([...data])
  }

  const handleClickRemoveProfile = ({ id, entityId }) => {
    const updatedProfiles = selectedProfiles.filter((profile) => profile.id !== id || profile.entityId !== entityId)
    handleSaveSelectedProfiles({ data: updatedProfiles })
    setEntitySelectorKey(moment().valueOf())
  }

  const generateTimeData = (publish_at) => {
    // here whenToPublish is not needed to be updated, because whenToPublish value for this function is set only via post creation. Edit already has time

    let publish_at_updated = publish_at

    if (whenToPublish === PUBLISH_LATER && selectedCalendarDate && !publish_at_updated) {
      publish_at_updated = selectedCalendarDate
    }

    const now = publish_at_updated
      ? moment.tz(publish_at_updated, true, selectedTimezone)
      : moment
          .tz(moment(), true, selectedTimezone)
          .clone()
          .add(5, 'minutes')

    const date = now.format(DATE_FORMAT)
    const hour = now.format('hh')
    const minute = now.format('mm')
    const interval = now.format('A')

    return {
      date: whenToPublish === PUBLISH_DRAFT ? selectedCalendarDate || date : date,
      hour,
      minute,
      interval,
      date_string: `${date} ${hour}:${minute} ${interval}`,
    }
  }

  const handleUpdateCalendarEventsForPublish = ({ eventsForPublish, post }) => {
    const updatedPost = post || savedPost || {}

    const events = []

    eventsForPublish.forEach(({ publishTimeData: publishTimeDataEvent, scheduleIndividually, profiles }, index) => {
      let publishTimeData = publishTimeDataEvent
      profiles.forEach(({ publishTimeData: publishTimeDataProfile, id, code }, childIndex) => {
        let updatedPostText = updatedPost.postText || ''

        if (updatedPost.customizations && updatedPost.customizations[code]) {
          const profileCustomizations = updatedPost.customizations[code][id] || {}

          updatedPostText = profileCustomizations.message || updatedPost.postText || ''
        }

        if (scheduleIndividually) {
          publishTimeData = publishTimeDataProfile
        }

        const { date, hour, minute, interval } = publishTimeData

        const eventTime = moment(`${date} ${hour}:${minute} ${interval}`, `${DATE_FORMAT} hh:mm A`).format()

        const { updatedTextTransformedMentions } = mentionsTransformer({
          body: updatedPostText,
          agencyMentions: updatedPost.agencyMentions || [],
          agencyMentionsSkipped: updatedPost.agencyMentionsSkipped || [],
          network: code,
          color: COLOR_CONSTANTS.COBALT,
        })

        const data = {
          tempId: `${index}${childIndex}`,
          title: updatedTextTransformedMentions || updatedPostText,
          start: eventTime,
          end: eventTime,
          profile_gid: id,
          publication_group_id: updatedPost.publication_group_id,
          postImages: [],
          postVideos: [],
          postDocuments: [],
          postMixedMedias: [],
          link: null,
        }

        if (code === CUSTOM) {
          data.title = stripHtml(data.title)
        }

        if (updatedPost.postComponent === POST_IMAGE) {
          data.postImages = updatedPost.postImages
        } else if (updatedPost.postComponent === POST_VIDEO) {
          data.postVideos = updatedPost.postVideos
        } else if (updatedPost.postComponent === POST_DOCUMENT) {
          data.postDocuments = updatedPost.postDocuments
        } else if (updatedPost.postComponent === POST_MIXED_MEDIA) {
          data.postMixedMedias = updatedPost.postMixedMedias
        } else if (updatedPost.postComponent === POST_LINK && updatedPost.link && updatedPost.link.url) {
          data.link = updatedPost.link
        }
        events.push(data)
      })
    })
    setCalendarEventsForPublish([...events])
  }

  const getQueueData = async ({ selectedProfilesGroupedTemp, whenToQueueTemp, post }) => {
    setIsGettingQueueData(true)
    const entityPublishValuesTemp = selectedProfilesGroupedTemp || entityPublishValues
    const body = { targets: [], queue_labels: [] }

    if (selectedQueueLabel && selectedQueueLabel.value) {
      body.queue_labels.push(selectedQueueLabel.value)
    }

    entityPublishValuesTemp.forEach(({ profiles, timezone = DEFAULT_TIME_ZONE, entityId, entityType }) => {
      profiles.forEach(({ id }) => {
        body.targets.push({
          profile_gid: id,
          timezone,
          publish_at: whenToQueueTemp || whenToQueue,
          entityId,
          entityType,
        })
      })
    })
    try {
      const response = await request({
        method: 'POST',
        path: `${ROUTE_QUEUE}/times`,
        body,
      })

      if (response && !response.error) {
        entityPublishValuesTemp.forEach((item) => {
          const { profiles } = item
          item.scheduleIndividually = true
          profiles.forEach((profile) => {
            const foundTarget = response.find(({ profile_gid }) => profile_gid === profile.id)
            if (foundTarget) {
              const { posts = [] } = foundTarget

              if (calendarEvents.length !== 0 && posts && posts.length !== 0) {
                posts.forEach(({ publication_gid, publish_at_str }) => {
                  const foundCalendarEventIndex = calendarEvents.findIndex(({ id }) => id === publication_gid)
                  if (foundCalendarEventIndex > -1) {
                    calendarEvents[foundCalendarEventIndex].start = publish_at_str
                    calendarEvents[foundCalendarEventIndex].end = publish_at_str
                  }
                })
                setCalendarEvents([...calendarEvents])
              }

              const publishTimeData = generateTimeData(foundTarget.queue_time)
              profile.publishTimeData = { ...publishTimeData }
              profile.savedPublishTimeData = { ...publishTimeData }
              profile.posts_in_queue = foundTarget.posts_in_queue
              profile.posts = posts
              profile.changePublishTimeFromQueueToSchedule = false
              profile.queue_labels = foundTarget.queue_labels
            }
          })
        })
        setEntityPublishValues([...entityPublishValuesTemp])
        handleUpdateCalendarEventsForPublish({ eventsForPublish: entityPublishValuesTemp, post })
      } else {
        Alert.error(ERROR_MESSAGE, { timeout: 5000 })
      }
    } catch (error) {
      errorHelper({ error, componentName: PublishModal.displayName, functionName: 'getQueueData' })
    } finally {
      setIsGettingQueueData(false)
    }
  }

  const getQueueLabels = async ({ selectedProfilesGroupedTemp }) => {
    const entityPublishValuesTemp = selectedProfilesGroupedTemp || entityPublishValues
    const body = { targets: [] }

    entityPublishValuesTemp.forEach(({ profiles, entityId }) => {
      profiles.forEach(({ id }) => {
        body.targets.push({
          profile_gid: id,
          entityId,
        })
      })
    })
    try {
      const response = await request({
        method: 'POST',
        path: `${ROUTE_QUEUE}/labels`,
        body,
      })
      if (response && !response.error) {
        const { labels = [] } = response || {}
        setUsedQueueLabels([DEFAULT_QUEUE_LABEL, ...labels])
      }
      // eslint-disable-next-line no-empty
    } catch (error) {
      errorHelper({ error, componentName: PublishModal.displayName, functionName: 'getQueueLabels', showAlert: false })
    }
  }

  const handleClickOpenAssignModal = () => {
    setIsAssignModalOpen(true)
  }

  const handleClickCloseAssignModal = () => {
    setIsAssignModalOpen(false)
  }

  const handleClickSaveWorkflow = (body) => {
    savedPost.workflow = body
    setSavedPost({ ...savedPost })
    handleClickCloseAssignModal()
  }

  const handleClickOpenRejectApproveNotesModal = ({ type }) => {
    setRejectApproveNotesModalOptions({ ...{ isOpen: true, type } })
  }

  const handleClickCloseRejectApproveNotesModal = () => {
    setRejectApproveNotesModalOptions({ ...DEFAULT_REJECT_APPROVE_NOTES_MODAL_OPTIONS })
  }

  const handleClickOpenSetTimezoneModal = () => {
    setIsSetTimezoneModalOpen(true)
  }

  const handleClickCloseSetTimezoneModal = () => {
    setIsSetTimezoneModalOpen(false)
  }

  const handleClickCopyPostLink = async () => {
    await navigator.clipboard.writeText(
      `${process.env.NEXT_PUBLIC_SITE_URL}/calendar?id=${props.postForEdit.id || props.postForEdit.draftId}`
    )
    Alert.success(`Post link has been copied.`, { timeout: 5000 })
  }

  const handleClickDuplicatePost = () => {
    handleDismiss({
      closePublisherWrapper: false,
      scheduleTypeData: {
        selectedPost: purifyingPost({ post: props.postForEdit }),
        selectedEntitiesForSelectedPost: props.selectedEntities,
        selectedProfilesForSelectedPost: props.selectedProfiles,
      },
    })
  }

  const findTargetsUpdatedInPublicationGroup = ({ post: postForChecking }) => {
    const post = JSON.parse(JSON.stringify(postForChecking))

    const targets_updated = []
    const targets_updated_string = []

    const customization_key_not_for_checking = [
      'view',
      'message',
      'postImages',
      'postVideos',
      'postDocuments',
      'postMixedMedias',
      'profile_gid',
      'attachments',
      'sync_media_keys',
    ]

    if (props.postForEdit && props.postForEdit.publication_group_id) {
      const {
        postText: defaultPostText = '',
        customizations: defaultCustomizations = {},
        sync_media_keys: default_post_sync_media_keys = '',
      } = props.postForEdit

      const { postText = '', sync_media_keys: post_sync_media_keys = '' } = post

      const publication_group_profiles_updated = {}

      selectedProfiles.forEach((profile) => {
        let isSame = true

        const { id, name, username, nickname, code } = profile

        const postCustomization =
          post.customizations && post.customizations[code] ? post.customizations[code][id] || {} : {}

        const { message = '', sync_media_keys } = postCustomization || {}

        const message_updated = message || postText

        let sync_media_keys_updated = sync_media_keys
        if (typeof sync_media_keys_updated === 'undefined') {
          sync_media_keys_updated = post_sync_media_keys
        }

        const defaultPostCustomization =
          defaultCustomizations && defaultCustomizations[code] ? defaultCustomizations[code][id] || {} : {}

        const { message: defaultMessage = defaultPostText, sync_media_keys: default_sync_media_keys } =
          defaultPostCustomization || {}

        let default_sync_media_keys_updated = default_sync_media_keys
        if (typeof default_sync_media_keys_updated === 'undefined') {
          default_sync_media_keys_updated = default_post_sync_media_keys
        }

        if (message_updated !== defaultMessage || sync_media_keys_updated !== default_sync_media_keys_updated) {
          isSame = false
        } else {
          for (const key in postCustomization) {
            if (postCustomization.hasOwnProperty(key) && !customization_key_not_for_checking.includes(key)) {
              if (key === 'comments') {
                const filteredComments = postCustomization[key]
                  ? postCustomization[key].filter((comment) => comment)
                  : []

                const filteredCommentsDefault = defaultPostCustomization[key]
                  ? defaultPostCustomization[key].filter((comment) => comment)
                  : []

                if (filteredComments.length !== filteredCommentsDefault.length) {
                  isSame = false
                }
              } else if (
                (postCustomization[key] && !defaultPostCustomization[key]) ||
                (!postCustomization[key] && defaultPostCustomization[key])
              ) {
                isSame = false
              } else if (
                postCustomization[key] &&
                defaultPostCustomization[key] &&
                JSON.stringify(postCustomization[key]) !== JSON.stringify(defaultPostCustomization[key])
              ) {
                isSame = false
              }
            }
          }
        }

        if (!isSame) {
          const { display } = getProfileDisplay({ profile })

          publication_group_profiles_updated[id] = { id, name: `- ${nickname || name || username} ${display}` }
        }
      })

      for (const profile_gid in publication_group_profiles_updated) {
        if (publication_group_profiles_updated.hasOwnProperty(profile_gid)) {
          const { name } = publication_group_profiles_updated[profile_gid]

          targets_updated.push(Number(profile_gid))
          targets_updated_string.push(name)
        }
      }
    }

    return { targets_updated, targets_updated_string: targets_updated_string.join('<br/>') }
  }

  const findTargetsRemovedFromPublicationGroup = () => {
    const targets_removed = []
    const targets_removed_string = []

    if (props.postForEdit && props.postForEdit.publication_group_id) {
      const publication_group_profiles_removed = {}

      props.selectedProfiles.forEach((profile) => {
        const { id, name, username, nickname } = profile

        const { display } = getProfileDisplay({ profile })

        publication_group_profiles_removed[id] = { id, name: `- ${nickname || name || username} ${display}` }
      })

      selectedProfiles.forEach(({ id }) => {
        delete publication_group_profiles_removed[id]
      })

      if (props.blockedProfilesForSelectedPost) {
        props.blockedProfilesForSelectedPost.forEach(({ profile_gid }) => {
          delete publication_group_profiles_removed[profile_gid]
        })
      }

      for (const profile_gid in publication_group_profiles_removed) {
        if (publication_group_profiles_removed.hasOwnProperty(profile_gid)) {
          const { name } = publication_group_profiles_removed[profile_gid]

          targets_removed.push(profile_gid)
          targets_removed_string.push(name)
        }
      }
    }

    return { targets_removed, targets_removed_string: targets_removed_string.join('<br/>') }
  }

  const handleCollectPostData = ({ selectedProfilesGroupedTemp, review, workflow, whenToPublishSpecial }) => {
    let post = savedPost
    if (postCreationComponentRef && postCreationComponentRef.current) {
      ;({ post } = postCreationComponentRef.current.handleReturnPost())
    }

    const whenToPublishTemp = whenToPublishSpecial || whenToPublish

    const {
      postText,
      postComponent,
      postImages = [],
      postVideos = [],
      postDocuments = [],
      postMixedMedias = [],
      labels,
      advocacy_gid,
      advocacy,
      using_ai = false,
    } = post || {}

    const data = {
      message: postText || '',
      targets: [],
      labels: (labels || []).map(({ value }) => value),
      customizations: [],
      targets_removed: [],
      targets_updated: [],
      using_ai,
    }

    const collectCustomization = ({ code, id }) => {
      const postCustomization = post.customizations?.[code]?.[id]
        ? JSON.parse(JSON.stringify(post.customizations[code][id]))
        : {}

      if (post.customizations) {
        const { video_processing } = post.customizations
        if (typeof video_processing !== 'undefined') {
          postCustomization.video_processing = video_processing
        }
      }

      postCustomization.kind = code
      postCustomization.profile_gid = id

      delete postCustomization.view
      delete postCustomization.sync_media_keys

      const {
        message = '',
        postImages: customizedPostImages = postImages,
        postVideos: customizedPostVideos = postVideos,
        postDocuments: customizedPostDocuments = postDocuments,
        postMixedMedias: customizedPostMixedMedias = postMixedMedias,
      } = postCustomization

      let customizedPostComponent = postCustomization.postComponent

      if (typeof customizedPostComponent === 'undefined') {
        customizedPostComponent = postComponent
      }

      const { mentionsFromPost } = mentionsTransformer({
        body: message || post.postText,
        color: 'rgb(0,0,0)',
        fontWeight: 400,
        agencyMentions: post.agencyMentions || [],
        network: code,
      })

      const mentionsForNetwork = []
      mentionsFromPost.forEach((item) => {
        if (item[code]) {
          const { id } = item[code]
          mentionsForNetwork.push({ id, mention: item.name })
        }
      })

      if (mentionsForNetwork.length !== 0) {
        if (mentionsForNetwork.length !== 0) {
          postCustomization.mentions = mentionsForNetwork
        }
      }

      const attachments = collectPostAttachments({
        post: {
          postComponent: customizedPostComponent,
          postImages: customizedPostImages,
          postVideos: customizedPostVideos,
          postDocuments: customizedPostDocuments,
          postMixedMedias: customizedPostMixedMedias,
        },
      })

      const { comments = [] } = postCustomization

      if (comments && comments.length !== 0) {
        const updated_comments = []

        comments.forEach((comment) => {
          const data = { message: comment.message }

          const attachments = collectPostAttachments({
            post: comment,
          })

          if (attachments) {
            data.attachments = attachments
          }

          if (data.message || (data.attachments && data.attachments.length > 0)) {
            updated_comments.push(data)
          }
        })

        if (updated_comments.length !== 0) {
          postCustomization.comments = updated_comments
        }
      }

      if (attachments) {
        postCustomization.attachments = attachments
      }

      data.customizations.push(postCustomization)
    }

    const updatedWorkFlow = post.workflow

    if (updatedWorkFlow) {
      data.workflow = post.workflow
    }

    const updatedReview = review || post.review
    if (updatedReview) {
      const { reviewer: { value } = {}, note_to_reviewer = '', note_from_reviewer = '' } = updatedReview
      data.review = { reviewer: value, note_to_reviewer, note_from_reviewer }
    }

    if (post.publication_group_id) {
      data.publication_group_id = post.publication_group_id
      data.group_update = true

      const { targets_removed } = findTargetsRemovedFromPublicationGroup()

      data.targets_removed = targets_removed

      const { targets_updated } = findTargetsUpdatedInPublicationGroup({ post })

      data.targets_updated = targets_updated
    }

    if (post.idea_gid) {
      data.idea_gid = post.idea_gid
    }

    if (post.advocacy_publication_group_gid) {
      data.advocacy_publication_group_gid = post.advocacy_publication_group_gid
    }

    if (advocacy_gid) {
      data.advocacy_gid = advocacy_gid
    }

    if (whenToPublishTemp === PUBLISH_DRAFT) {
      data.draft = true
    }

    if (whenToPublishSpecial === PUBLISH_SAVE_CHANGES && post.publication_group_id) {
      data.save_changes = true
    }

    const entityPublishValuesTemp = selectedProfilesGroupedTemp || entityPublishValues

    const groupedProfilesByNetwork = {}

    entityPublishValuesTemp.forEach(
      ({ scheduleIndividually, publishTimeData, profiles, timezone = DEFAULT_TIME_ZONE, entityId }) => {
        let publishTime = publishTimeData

        profiles.forEach((profile) => {
          const { id, code, publishTimeData, entityId, changePublishTimeFromQueueToSchedule } = profile
          const targetsData = { profile_gid: id, network: code, timezone, labels: [] }

          if (scheduleIndividually) {
            publishTime = publishTimeData
          }

          if (advocacy_gid && advocacy?.publish_at) {
            // this piece of code is only for Advocacy posts
            const date_raw = `${advocacy.publish_at.date} ${advocacy.publish_at.hour}:${advocacy.publish_at.minute} ${advocacy.publish_at.interval}`
            targetsData.publish_at_raw = date_raw
            targetsData.publish_at = moment(date_raw, `${DATE_FORMAT} hh:mm A`).format(`${DATE_FORMAT} HH:mm`)
          } else if (whenToPublishTemp === PUBLISH_NOW) {
            targetsData.publish_at = 'now'
          } else if (whenToPublishTemp === PUBLISH_WHEN_READY) {
            targetsData.publish_at = 'now+5'
          } else if (whenToPublishTemp === PUBLISH_QUEUE) {
            if (changePublishTimeFromQueueToSchedule) {
              const date_raw = `${publishTime.date} ${publishTime.hour}:${publishTime.minute} ${publishTime.interval}`
              targetsData.publish_at_raw = date_raw
              targetsData.publish_at = moment(date_raw, `${DATE_FORMAT} hh:mm A`).format(`${DATE_FORMAT} HH:mm`)
            } else {
              targetsData.publish_at = whenToQueue
            }

            if (selectedQueueLabel && selectedQueueLabel.value) {
              targetsData.labels.push(selectedQueueLabel.value)
            }
          } else {
            let publishTimeUpdated = publishTime

            if (entityPublishValuesGrouped.isTogether) {
              targetsData.timezone = entityPublishValuesTemp[0].timezone
              publishTimeUpdated = entityPublishValuesTemp[0].publishTimeData
            }

            const date_raw = `${publishTimeUpdated.date} ${publishTimeUpdated.hour}:${publishTimeUpdated.minute} ${publishTimeUpdated.interval}`

            targetsData.publish_at_raw = date_raw

            targetsData.publish_at = moment(date_raw, `${DATE_FORMAT} hh:mm A`).format(`${DATE_FORMAT} HH:mm`)
          }
          targetsData.entity_gid = entityId

          data.targets.push(targetsData)

          if (!groupedProfilesByNetwork[code]) {
            groupedProfilesByNetwork[code] = { profiles: [] }
          }

          groupedProfilesByNetwork[code].profiles.push(profile)
        })
      }
    )

    // eslint-disable-next-line guard-for-in
    for (const network in groupedProfilesByNetwork) {
      const { profiles = [] } = groupedProfilesByNetwork[network]

      profiles.forEach((profile) => {
        collectCustomization(profile)
      })
    }

    const attachments = collectPostAttachments({ post })

    if (attachments) {
      data.attachments = attachments
    }

    if (post.link && post.link.url) {
      if (!post.link.shorten) {
        post.link.shorten = { type: 'DEFAULT' }
      }

      data.link = {
        url: post.link.url,
        title: post.link.title || '',
        description: post.link.description || '',
        shorten: post.link.shorten,
        active: post.postComponent === POST_LINK,
      }

      if (post.link.utm_tracking) {
        data.link.tracking = {
          params:
            post.link.tracking && post.link.tracking.params
              ? post.link.tracking.params.map(({ name, type, value }) => {
                  const data = { name, type: type.value }
                  if (data.type === CUSTOM_TYPE) {
                    data.value = value
                  }
                  return data
                })
              : [],
          disabled: false,
        }

        if (post.link.save_utm_tracking) {
          data.link.save_utm_tracking = true
          data.link.utmTrackingId = post.link.utmTrackingId
        }
      } else if (typeof post.link.utm_tracking !== 'undefined') {
        data.link.tracking = {
          disabled: true,
        }
      }

      if (post.link.id) {
        data.link.media_gid = post.link.id
      }
    } else {
      data.link = null
    }

    collectAdvocacyDataBeforePublishing({ data, advocacy, advocacy_gid })

    return data
  }

  const handleClickSubmitPost = async ({
    selectedProfilesGroupedTemp,
    scheduleType = SCHEDULE_TYPE_DEFAULT,
    whenToPublishSpecial,
    loadingButton,
  }) => {
    const data = handleCollectPostData({ selectedProfilesGroupedTemp, whenToPublishSpecial })

    handleSubmitPost({
      body: data,
      type: 'publish',
      selectedProfiles,
      selectedEntities,
      scheduleTypeData:
        scheduleType === SCHEDULE_TYPE_DEFAULT
          ? null
          : {
              selectedPost: scheduleType === SCHEDULE_TYPE_DUPLICATE ? purifyingPost({ post: savedPost }) : {},
              selectedProfilesForSelectedPost: selectedProfiles,
              selectedEntitiesForSelectedPost: selectedEntities,
              selectedCalendarDate,
            },
      loadingButton: loadingButton || LOADING_BUTTON_SUBMIT,
      cleaningGarbageModalFunction: () => {
        // eslint-disable-next-line no-use-before-define
        handleClearAllMediaIntervals()
      },
    })
  }

  const handleClickSaveRejectApproveNotes = async (body) => {
    const data = handleCollectPostData({ review: body })
    if (!data.review) {
      data.review = {}
    }

    data.review.approved = rejectApproveNotesModalOptions.type === 'approve'

    await handleSubmitPost({
      body: data,
      type: 'publish',
      selectedProfiles,
      selectedEntities,
      loadingButton: rejectApproveNotesModalOptions.type === 'approve' ? LOADING_BUTTON_APPROVE : LOADING_BUTTON_REJECT,
      cleaningGarbageModalFunction: () => {
        handleClickCloseRejectApproveNotesModal()
        // eslint-disable-next-line no-use-before-define
        handleClearAllMediaIntervals()
      },
    })
  }

  const handleClickSavePost = async () => {
    const data = handleCollectPostData({ whenToPublishSpecial: PUBLISH_SAVE_CHANGES })

    handleSubmitPost({
      body: data,
      type: 'publish',
      selectedProfiles,
      selectedEntities,
      loadingButton: LOADING_BUTTON_SAVE,
      cleaningGarbageModalFunction: () => {
        // eslint-disable-next-line no-use-before-define
        handleClearAllMediaIntervals()
      },
    })
  }

  const handleClickCloseSendForApprovalProfileGroupsModal = () => {
    setSendForApprovalProfileGroupsModal({ ...DEFAULT_SEND_FOR_APPROVAL_PROFILE_GROUPS_MODAL })
  }

  const handleClickSendForApprovalPost = async ({
    scheduleType,
    selectedEntitiesForApproval = [],
    avoidApprovalModalCheck,
  }) => {
    if (props.postForEdit && props.postForEdit.publication_group_id && !avoidApprovalModalCheck) {
      const entityPublishValuesTemp = [...entityPublishValues]

      entityPublishValuesTemp.forEach((entity) => {
        if (
          sendForApprovalProfileGroupsModal.selectedEntities &&
          sendForApprovalProfileGroupsModal.selectedEntities.length !== 0
        ) {
          const foundSelectedEntity = sendForApprovalProfileGroupsModal.selectedEntities.find(
            ({ entityId }) => entityId === entity.entityId
          )

          if (foundSelectedEntity) {
            if (foundSelectedEntity.selected) {
              entity.selected = true
            }

            if (foundSelectedEntity.isOpen) {
              entity.isOpen = true
            }
          }
        }

        if (entity.profiles) {
          entity.profiles.forEach((profile) => {
            if (props.postForEdit.postsData && props.postForEdit.postsData[profile.id]) {
              profile.status = props.postForEdit.postsData[profile.id].status
            }
          })
        }
      })

      if (entityPublishValuesTemp.length === 1) {
        handleClickSendForApprovalPost({
          scheduleType,
          selectedEntitiesForApproval: entityPublishValuesTemp,
          avoidApprovalModalCheck: true,
        })
      } else {
        setSendForApprovalProfileGroupsModal({
          ...{
            isOpen: true,
            selectedEntities: entityPublishValuesTemp,
            callbackFunc: ({ selectedEntities }) => {
              handleClickSendForApprovalPost({
                scheduleType,
                selectedEntitiesForApproval: selectedEntities,
                avoidApprovalModalCheck: true,
              })
              setSendForApprovalProfileGroupsModal({
                ...DEFAULT_SEND_FOR_APPROVAL_PROFILE_GROUPS_MODAL,
                selectedEntities,
              })
            },
          },
        })
      }
    } else {
      const data = handleCollectPostData({})

      data.send_for_approval = selectedEntitiesForApproval
        ? selectedEntitiesForApproval.map(({ entityId }) => entityId)
        : []

      handleSubmitPost({
        body: data,
        type: 'publish',
        selectedProfiles,
        selectedEntities,
        scheduleTypeData:
          scheduleType === SCHEDULE_TYPE_DEFAULT
            ? null
            : {
                selectedPost: scheduleType === SCHEDULE_TYPE_DUPLICATE ? purifyingPost({ post: savedPost }) : {},
                selectedProfilesForSelectedPost: selectedProfiles,
                selectedEntitiesForSelectedPost: selectedEntities,
                selectedCalendarDate,
              },
        loadingButton: LOADING_BUTTON_SUBMIT,
        cleaningGarbageModalFunction: () => {
          // eslint-disable-next-line no-use-before-define
          handleClearAllMediaIntervals()
        },
      })
    }
  }

  const handleClickOpenDeletePostModal = () => {
    setIsDeletePostModalOpen(true)
  }

  const handleClickCloseDeletePostModal = () => {
    setIsDeletePostModalOpen(false)
  }

  const handleClickDeletePost = async (body) => {
    if (props.postForEdit) {
      const { group_posts = [], publication_group_data } = props.postForEdit

      const group_posts_updated =
        publication_group_data && publication_group_data.group_posts ? publication_group_data.group_posts : group_posts

      body.items = []

      selectedProfiles.forEach(({ id, entityId }) => {
        const foundPost = group_posts_updated.find(
          ({ profile_gid, entity_gid }) => profile_gid === id && entity_gid === entityId
        )
        if (foundPost) {
          body.items.push({ id: foundPost.id, type: '' })
        }
      })

      if (body.items.length === 0) {
        body.items = group_posts_updated.map(({ id }) => ({ id, type: '' }))
      }

      await handleDeletePost({ data: body, type: 'publish' })
      handleClickCloseDeletePostModal()
    }
  }

  const handleClickApproveRejectReminderPost = async ({ type }) => {
    if (props.postForEdit) {
      let posts_ids = []

      const { group_posts = [], publication_group_data } = props.postForEdit

      const group_posts_updated =
        publication_group_data && publication_group_data.group_posts ? publication_group_data.group_posts : group_posts

      selectedProfiles.forEach(({ id, entityId }) => {
        const foundPost = group_posts_updated.find(
          ({ profile_gid, entity_gid }) => profile_gid === id && entity_gid === entityId
        )
        if (foundPost) {
          posts_ids.push(id)
        }
      })

      if (posts_ids.length === 0) {
        posts_ids = group_posts_updated.map(({ id }) => id)
      }

      await handleApproveRejectReminderPost({
        data: { posts_ids },
        id: props.postForEdit.id,
        type: 'publish',
        action: type,
      })
    }
  }

  const handleClickOpenComments = () => {
    const { entities, entity_gid, publication_group_id, timezone } = savedPost

    const entity_gids = entities.map(({ entityId }) => entityId)

    if (entity_gid) {
      const foundEntity = entity_gids.find((id) => id === entity_gid)
      if (!foundEntity) {
        entity_gids.push(entity_gid)
      }
    }

    setSliderOptions({
      isOpen: true,
      data: { comment_type_id: publication_group_id, type: COMMENT_TYPE_PUBLICATION, entity_gids, timezone },
    })
  }

  const handleClickCloseComments = ({ commentsCounter }) => {
    if (postCreationComponentRef && postCreationComponentRef.current) {
      const { post } = postCreationComponentRef.current.handleReturnPost()
      setSavedPost({ ...post, comments: commentsCounter })
    } else {
      setSavedPost({
        ...savedPost,
        comments: commentsCounter,
      })
    }

    setSliderOptions({ ...DEFAULT_SLIDER_OPTIONS })
  }

  const handleClickOpenHistoryModal = () => {
    setIsHistoryPostModalOpen(true)
  }

  const handleClickCloseHistoryModal = () => {
    setIsHistoryPostModalOpen(false)
  }

  const handleClickNextStep = async (props) => {
    const { whenToPublishSpecial, loadingButton } = props || {}

    let whenToPublishTemp = whenToPublishSpecial || whenToPublish

    if (whenToPublishTemp === PUBLISH_SAVE_CHANGES && savedPost.draft) {
      whenToPublishTemp = PUBLISH_DRAFT
    }

    await setCheckForErrors(true)
    setIsStepClicked(true)
    const { post } = await postCreationComponentRef.current.handleCheckPostForError({
      whenToPublishSpecial: whenToPublishTemp,
    })

    let error = ''
    let showErrorAlert = true
    if (selectedProfiles.length === 0) {
      error = 'Please select at least one social profile.'
    }

    const { networksErrors = {}, mediaUploadingError: { isMediaUploading = false, mediaType } = {} } = post.errors || {}

    let networksString = ''

    for (const i in networksErrors) {
      if (networksErrors[i] && networksErrors[i].hasErrors) {
        networksString = `${networksString ? `${networksString},` : ''} ${networksErrors[i].code
          .charAt(0)
          .toUpperCase()}${networksErrors[i].code.substr(1)}`
      }
    }

    if (networksString) {
      error = `Please check your content on the following social networks: ${networksString}`
    }

    if (whenToPublishTemp !== PUBLISH_DRAFT) {
      const doesntHaveUniversalMentions = postCreationComponentRef.current.handleCheckPostTextForNotCreatedMentions({
        callbackFunction: () => {
          handleClickNextStep(props)
        },
      })

      if (doesntHaveUniversalMentions) {
        // this is needed to block the next step flow and send the user to the mentions modal
        error = `Please create a 'universal mention' by specifying the handle on each social network.`
        showErrorAlert = false
      } else {
        const doesntHaveVariables = postCreationComponentRef.current.handleCheckPostTextForNotCreatedVariables({
          callbackFunction: () => {
            handleClickNextStep(props)
          },
        })

        if (doesntHaveVariables) {
          error = `Please create a 'variable' by specifying on each social profile.`
          showErrorAlert = false
        }
      }
    }

    if (!error && isMediaUploading) {
      error = `Your ${mediaType} is still uploading. Please wait just a bit longer before continuing.`
    }
    setIsStepClicked(false)
    if (error) {
      if (showErrorAlert) {
        Alert.error(error)
      }
    } else {
      let shouldSetEntityTimezone = false
      const publishTimeData = generateTimeData()

      setSavedPost({ ...post })

      let selectedProfilesGroupedTemp = {}

      // this part is for getting default time for a new added profile to the post while editing
      let defaultTime = null
      let isPublishingTimeTheSame = true

      if (post.publication_group_id) {
        selectedProfiles.forEach((profile) => {
          const { publish_at } = profile

          if (publish_at && isPublishingTimeTheSame) {
            const date = generateTimeData(publish_at)

            if (!defaultTime) {
              defaultTime = { ...date }
            } else if (defaultTime.date_string !== date.date_string) {
              isPublishingTimeTheSame = false
            }
          }
        })
      }

      if (!defaultTime) {
        defaultTime = generateTimeData(post.publish_at)
      }

      selectedProfiles.forEach((profile) => {
        const item = JSON.parse(JSON.stringify(profile))
        const key = item.entityId
        if (post.publication_group_id) {
          if (item.publish_at) {
            item.publishTimeData = { ...generateTimeData(item.publish_at) }
          } else if (isPublishingTimeTheSame) {
            item.publishTimeData = { ...defaultTime }
          } else {
            item.publishTimeData = { ...publishTimeData }
          }
        } else {
          item.publishTimeData = { ...publishTimeData }
        }

        if (key) {
          if (!selectedProfilesGroupedTemp[key]) {
            const { name, timezone, type, picture_url } = selectedEntities.find((entity) => entity.id === item.entityId)

            if (!timezone) {
              shouldSetEntityTimezone = true
            }

            selectedProfilesGroupedTemp[key] = {
              name,
              entityType: type,
              entityId: item.entityId,
              entityPictureUrl: picture_url,
              timezone,
              profiles: [],
              publishTimeData: item.publishTimeData,
              scheduleIndividually: false,
            }
          }

          if (item.publishTimeData.date_string !== selectedProfilesGroupedTemp[key].publishTimeData.date_string) {
            selectedProfilesGroupedTemp[key].scheduleIndividually = true
          }

          selectedProfilesGroupedTemp[key].profiles.push(item)
        }
      })

      selectedProfilesGroupedTemp = Object.values(selectedProfilesGroupedTemp)

      selectedProfilesGroupedTemp.forEach((item) => {
        const { entityId, entityType, profiles = [] } = item
        const foundEntity = entityPublishValues.find(
          (entity) => entity.entityId === entityId && entity.entityType === entityType
        )

        if (foundEntity) {
          item.publishTimeData = foundEntity.publishTimeData
          item.scheduleIndividually = foundEntity.scheduleIndividually
          if (profiles.length !== 0 && foundEntity.profiles.length !== 0) {
            profiles.forEach((item) => {
              const { id, entityType } = item
              const foundProfile = foundEntity.profiles.find(
                (profile) => profile.id === id && profile.entityType === entityType
              )
              if (foundProfile) {
                item.publishTimeData = foundProfile.publishTimeData
              }
            })
          }
        }
      })

      if (selectedProfilesGroupedTemp.length !== 1 && whenToPublishTemp === PUBLISH_LATER) {
        const profilesTemp = []

        let defaultTime = null
        let isPublishingTimeTheSame = true

        selectedProfilesGroupedTemp.forEach((item) => {
          const { profiles, publishTimeData } = item

          if (profilesTemp.length < MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS) {
            let defaultTimeProfile = null
            let isPublishingTimeTheSameProfile = true

            profiles.forEach((profile) => {
              if (profilesTemp.length < MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS) {
                profilesTemp.push(profile)
              }

              if (!defaultTimeProfile) {
                defaultTimeProfile = { ...profile.publishTimeData }
              } else if (defaultTimeProfile.date_string !== profile.publishTimeData.date_string) {
                isPublishingTimeTheSameProfile = false
              }
            })

            item.scheduleIndividually = !isPublishingTimeTheSameProfile

            if (item.scheduleIndividually) {
              isPublishingTimeTheSame = false
            }
          }

          if (!defaultTime) {
            defaultTime = { ...publishTimeData }
          } else if (defaultTime.date_string !== publishTimeData.date_string) {
            isPublishingTimeTheSame = false
          }
        })

        setEntityPublishValuesGrouped({
          ...{
            isMultipleEntityGroups: true,
            isTogether: isPublishingTimeTheSame,
            profiles: profilesTemp,
          },
        })
      } else {
        setEntityPublishValuesGrouped({
          ...{
            isMultipleEntityGroups: false,
            isTogether: false,
            profiles: [],
          },
        })
      }

      setEntityPublishValues([...selectedProfilesGroupedTemp])

      if (shouldSetEntityTimezone) {
        handleClickOpenSetTimezoneModal()
      } else {
        const nextFunction = async () => {
          if (whenToPublishTemp === PUBLISH_DRAFT || whenToPublishTemp === PUBLISH_SAVE_CHANGES) {
            await handleClickSubmitPost({ selectedProfilesGroupedTemp, whenToPublishSpecial, loadingButton })
          } else {
            if (whenToPublishSpecial) {
              setWhenToPublish(whenToPublishSpecial)
            }

            setIsStepClicked(true)

            let allowedNextStep = true

            const {
              video_processing: user_settings_video_processing = true,
              image_processing: user_settings_image_processing = true,
            } = user.settings || {}

            const {
              video_processing = user_settings_video_processing || true,
              image_processing = user_settings_image_processing || true,
            } = post.customizations

            if (
              whenToPublishTemp === PUBLISH_NOW &&
              video_processing &&
              !clickedNextStepFromVideoProcessingWarningModal
            ) {
              if (
                (post.postComponent === POST_VIDEO && post.postVideos && post.postVideos.length !== 0) ||
                (post.postComponent === POST_MIXED_MEDIA && post.postMixedMedias && post.postMixedMedias.length !== 0)
              ) {
                const now = moment.utc().valueOf()

                const PROCESSED_MEDIAS_INTERVALS = specialMediaProcessingDataComponentRef.current.getProcessedMediaIntervals()

                let foundVideoThatIsNewlyCreatedAndNotProcessed =
                  post.postComponent === POST_VIDEO &&
                  post.postVideos &&
                  post.postVideos.find(
                    ({ id, updated_at_time }) =>
                      PROCESSED_MEDIAS_INTERVALS[id] &&
                      now - updated_at_time <= 180000 &&
                      !PROCESSED_MEDIAS_INTERVALS[id].processed
                  )

                if (
                  !foundVideoThatIsNewlyCreatedAndNotProcessed &&
                  post.postComponent === POST_MIXED_MEDIA &&
                  post.postMixedMedias
                ) {
                  foundVideoThatIsNewlyCreatedAndNotProcessed = post.postMixedMedias.find(
                    ({ id, updated_at_time }) =>
                      PROCESSED_MEDIAS_INTERVALS[id] &&
                      now - updated_at_time <= 180000 &&
                      !PROCESSED_MEDIAS_INTERVALS[id].processed
                  )
                }

                if (!foundVideoThatIsNewlyCreatedAndNotProcessed) {
                  for (let i = 0; i < selectedProfiles.length; i++) {
                    const { id, code } = selectedProfiles[i]

                    const { customizations } = post
                    const { [code]: networkCustomizations } = customizations || {}
                    const { [id]: profileCustomizations } = networkCustomizations || {}

                    const { postVideos = [], postMixedMedias = [], postComponent: profilePostComponent } =
                      profileCustomizations || {}

                    if (
                      ((profilePostComponent || post.postComponent) === POST_VIDEO && postVideos.length !== 0) ||
                      ((profilePostComponent || post.postComponent) === POST_MIXED_MEDIA &&
                        postMixedMedias.length !== 0)
                    ) {
                      foundVideoThatIsNewlyCreatedAndNotProcessed =
                        (profilePostComponent || post.postComponent) === POST_VIDEO &&
                        postVideos.find(
                          ({ id, updated_at_time }) =>
                            PROCESSED_MEDIAS_INTERVALS[id] &&
                            now - updated_at_time <= 180000 &&
                            !PROCESSED_MEDIAS_INTERVALS[id].processed
                        )

                      if (
                        !foundVideoThatIsNewlyCreatedAndNotProcessed &&
                        (profilePostComponent || post.postComponent) === POST_MIXED_MEDIA
                      ) {
                        foundVideoThatIsNewlyCreatedAndNotProcessed = postMixedMedias.find(
                          ({ id, updated_at_time }) =>
                            PROCESSED_MEDIAS_INTERVALS[id] &&
                            now - updated_at_time <= 180000 &&
                            !PROCESSED_MEDIAS_INTERVALS[id].processed
                        )
                      }
                    }

                    if (foundVideoThatIsNewlyCreatedAndNotProcessed) {
                      break
                    }
                  }
                }

                if (foundVideoThatIsNewlyCreatedAndNotProcessed) {
                  allowedNextStep = false
                }
              }
            }

            if (allowedNextStep) {
              const { is_approver, approvers = [] } = await getApproversData({
                selectedProfiles,
                publication_group_gid: post.publication_group_id || null,
              })

              setApproversData({ ...{ is_approver, approvers } })

              await getCalendarEvents({
                profiles: selectedProfiles,
                setCalendarEvents: (events) => setCalendarEvents([...events]),
                calendarRef,
                centralDate: post.publication_group_id ? '' : publishTimeData.date,
                selectedTimezone,
                shouldCalendarShowUploadingCircle: false,
                calendarOptions,
                setCalendarOptions,
              })

              if (whenToPublishTemp === PUBLISH_QUEUE) {
                await Promise.allSettled([
                  getQueueData({ selectedProfilesGroupedTemp, post }),
                  getQueueLabels({ selectedProfilesGroupedTemp }),
                ])
              } else {
                handleUpdateCalendarEventsForPublish({ eventsForPublish: selectedProfilesGroupedTemp, post })
              }

              setStep(2)
              setIsStepClicked(false)
            } else {
              setIsStepClicked(false)

              let data = []

              if (post.postVideos) {
                data = [...data, ...post.postVideos]
              }

              if (post.postMixedMedias) {
                data = [...data, ...post.postMixedMedias.filter(({ type }) => type === VIDEO)]
              }

              setVideoProcessingWarningModalOptions({
                ...{
                  isOpen: true,
                  data,
                },
              })
            }
          }
        }

        const { targets_removed, targets_removed_string } = findTargetsRemovedFromPublicationGroup()

        if (targets_removed.length !== 0) {
          confirm({
            fn: () => () => {
              nextFunction()
            },
            message: `You have removed the following ${
              targets_removed.length === 1 ? 'profile' : 'profiles'
            } from this post: <br/> <br/> ${targets_removed_string}`,
            action: `Confirm`,
            cancel: 'Cancel',
          })
        } else {
          await nextFunction()
        }
      }
    }
  }

  const handleClickPreviousStep = () => {
    const { link } = savedPost
    const { title, description, picture_url } = link || {}
    if (title || description || picture_url) {
      link.hasLinkUrlInformation = true
      link.isLinkUrlCorrect = true
      link.isLinkUrlParsed = true
      savedPost.link = link
    }
    setClickedNextStepFromVideoProcessingWarningModal(false)
    setStep(1)
  }

  const handleUpdateEntities = (body) => {
    for (let i = 0; i < body.length; i++) {
      for (let j = 0; j < selectedEntities.length; j++) {
        if (selectedEntities[j].id === body[i].entity_id) {
          selectedEntities[j].timezone = body[i].timezone
          break
        }
      }
    }

    setSelectedEntities([...selectedEntities])
    props.handleUpdateEntities(body)
    handleClickNextStep()
  }

  const handleClearAllMediaIntervals = () => {
    specialMediaProcessingDataComponentRef.current.removeAllMediasFromProcessingInterval()
  }

  const handleClearAllMediaIntervalsAndDismiss = () => {
    handleClearAllMediaIntervals()
    handleDismiss()
  }

  const handleClickCloseModal = () => {
    if (!isPostSubmitting) {
      if (postHasBeenUpdated) {
        confirm({
          fn: () => () => {
            handleClearAllMediaIntervalsAndDismiss()
          },
          message: `Your post won't be saved. Do you want to continue?`,
          action: `Yes, I am sure`,
          cancel: 'No, cancel',
        })
      } else {
        handleClearAllMediaIntervalsAndDismiss()
      }
    }
  }

  const updateTooltipData = (data) => {
    if (data) {
      if (data.answeredId) {
        tooltipRef.current.handleShowTooltip({ contentComp: data.hoverTemplate, wrapperComp: data.currentTarget })
      }
    } else {
      tooltipRef.current.handleHideTooltip()
    }
  }

  const renderEvents = () => {
    const { 0: { publication_group_id } = {} } = calendarEventForPublish

    let updatedCalendarEvents = []

    if (publication_group_id) {
      updatedCalendarEvents = calendarEvents.filter((event) => event.publication_group_id !== publication_group_id)
    } else {
      updatedCalendarEvents = calendarEvents
    }

    let calendarEventsFiltered = [...calendarEventForPublish, ...updatedCalendarEvents]

    if (CALENDAR_POST_PREVIEW === CALENDAR_POST_PREVIEW_CONDENSED) {
      calendarEventsFiltered = groupCalendarEvents({ events: calendarEventsFiltered, selectedProfiles })
    }

    return calendarEventsFiltered
  }

  const handleSelectDay = (selectInfo) => {
    const { start: clickedDate, startStr } = selectInfo
    if (moment(clickedDate).diff(moment(), 'days') >= 0) {
      if (whenToPublish === PUBLISH_LATER || whenToPublish === PUBLISH_QUEUE) {
        entityPublishValues.forEach(({ profiles, scheduleIndividually, publishTimeData }, index) => {
          publishTimeData.date = startStr
          if (!scheduleIndividually && whenToPublish === PUBLISH_LATER) {
            entityPublishValues[index].publishTimeData = publishTimeData
          } else {
            profiles.forEach((item, childIndex) => {
              entityPublishValues[index].profiles[childIndex].publishTimeData = publishTimeData
            })
          }
        })

        setEntityPublishValues([...entityPublishValues])
        handleUpdateCalendarEventsForPublish({
          eventsForPublish: entityPublishValues,
        })
      }
    }
  }

  const handleClickReloadCalendar = async () => {
    const styledProgressWrapperEl = document.getElementById('publish-modal-progress-wrapper')

    if (styledProgressWrapperEl) {
      styledProgressWrapperEl.classList.add('progress-wrapper-show')
    }

    await getCalendarEvents({
      profiles: selectedProfiles,
      setCalendarEvents: (events) => setCalendarEvents([...events]),
      calendarRef,
      selectedTimezone,
      shouldCalendarShowUploadingCircle: false,
      calendarOptions,
      setCalendarOptions,
    })

    if (styledProgressWrapperEl) {
      styledProgressWrapperEl.classList.remove('progress-wrapper-show')
    }
  }

  const updateSettingsBeforeDateChanged = () => {
    const calendarData = calendarRef.current.getApi().getCurrentData()
    const { viewApi: { activeStart, activeEnd } = {}, viewTitle } = calendarData

    calendarOptions.viewTitle = viewTitle

    calendarOptions.midDateTime = moment((moment(activeStart).valueOf() + moment(activeEnd).valueOf()) / 2).format(
      'YYYY-MM-DD'
    )
    setCalendarOptions({ ...calendarOptions })
  }

  const handleChangeCalendarViewDates = async (param) => {
    if (calendarRef && calendarRef.current) {
      if (param === 'previous') {
        calendarRef.current.getApi().prev()
      } else if (param === 'next') {
        calendarRef.current.getApi().next()
      }

      updateSettingsBeforeDateChanged()

      await handleClickReloadCalendar()
    }
  }

  let rootProps = {}

  if (step === 1) {
    rootProps = getRootProps({ isDragActive })
  }

  let modalTitle = 'Publish'

  if (props?.postForEdit?.publication_group_id) {
    const { status } = props.postForEdit
    if (status === APPROVED || status === DRAFT) {
      modalTitle = 'Edit scheduled post'
    } else if (status.includes(ADVOCACY)) {
      modalTitle = 'Edit advocacy post'
    } else {
      modalTitle = 'Review scheduled post'
    }
  } else if (props.postForEdit && props.postForEdit.advocacy_gid) {
    modalTitle = 'Create advocacy post'
  }

  return (
    <StyledDialogOverlay disableEnforceFocus open={isOpen} onClose={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent {...rootProps} className="modal-content-shadow">
          <StyledFilesWrapper
            show={isDragActive || uploadingMediasAmount > 0}
            alignItems="center"
            justifyContent="center"
          >
            {uploadingMediasAmount === 0 ? (
              <Text fontSize="l" fontWeight="bold" color="white">
                Drag 'n' drop some files here
              </Text>
            ) : (
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
                px="m"
                className="cliploader-wrapper"
              >
                <Text mb="s" fontSize="l" fontWeight="bold" color="white">
                  {`${uploadingMediasAmount} ${uploadingMediasAmount === 1 ? 'file' : 'files'} left. `}
                  Please wait a moment...
                </Text>
                <ClipLoader size="50" />
              </Flex>
            )}
          </StyledFilesWrapper>
          <StyledDialogEnvironmentWrapper
            px="m"
            alignItems="center"
            justifyContent="space-between"
            $isTop
            $isAdvocacy={isAdvocacy}
          >
            <H4 my="m">{modalTitle}</H4>
            <Flex alignItems="center">
              {dataToLocalStorageOptions.canSave && dataToLocalStorageOptions.lastTimeSaved > 0 && step === 1 && (
                <Flex alignItems="center" justifyContent="center" height="100%">
                  <Text fontSize="xs" color="secondaryText">
                    {dataToLocalStorageOptions.lastTimeSavedMessage}
                  </Text>
                </Flex>
              )}
              {props.postForEdit && props.postForEdit.createdBy && (
                <Text ml="m" color="secondaryText" fontSize="s">
                  {props.postForEdit.createdBy}
                </Text>
              )}
              {props.postForEdit && (props.postForEdit.id || props.postForEdit.draftId) && (
                <Fragment>
                  <Tooltip
                    wrapperComp={
                      <StyledCommentIconWrapper mx="m" position="relative" onClick={handleClickOpenComments}>
                        <Image src="/assets/vistasocial/dashboard/comment_filled.svg" width="24px" height="24px" />
                        <StyledCommentCounterWrapper>
                          <Text fontSize="xxs" color="ternaryText">
                            {comments}
                          </Text>
                        </StyledCommentCounterWrapper>
                      </StyledCommentIconWrapper>
                    }
                    message="Comments - Platform"
                    isTriangleVisible={false}
                    width="170px"
                    defaultTopOffset={-70}
                    left="unset"
                    right="0"
                  />

                  <DropdownMenu
                    WrapperComp={
                      <ThreeDotsWrapper>
                        <ThreeDots>
                          <Icon.More stroke="icon_color_gray" />
                        </ThreeDots>
                      </ThreeDotsWrapper>
                    }
                    isDismissedOnClickInside
                  >
                    <DropdownMenu.Item onClick={handleClickCopyPostLink} isCursorPointer label="Copy post link" />

                    {handleClickDuplicatePost && (
                      <DropdownMenu.Item onClick={handleClickDuplicatePost} isCursorPointer label="Duplicate post" />
                    )}

                    {handleApproveRejectReminderPost && status === REMINDER_SENT && (
                      <DropdownMenu.Item
                        onClick={() => {
                          handleClickApproveRejectReminderPost({ type: 'acknowledge' })
                        }}
                        isCursorPointer
                        label="Mark as published"
                      />
                    )}

                    {handleApproveRejectReminderPost && status === REMINDER_SENT && (
                      <DropdownMenu.Item
                        onClick={() => {
                          confirm({
                            fn: () => () => handleClickApproveRejectReminderPost({ type: 'reject' }),
                            message: 'Are you sure you want to reject this post?',
                            action: 'Reject',
                          })
                        }}
                        isCursorPointer
                        label="Reject post"
                      />
                    )}

                    {handleDeletePost && (
                      <DropdownMenu.Item onClick={handleClickOpenDeletePostModal} isCursorPointer label="Remove post" />
                    )}

                    <DropdownMenu.Item
                      onClick={handleClickOpenComments}
                      isCursorPointer
                      label={`View comments (${comments})`}
                    />

                    <DropdownMenu.Item onClick={handleClickOpenHistoryModal} isCursorPointer label="View history" />
                  </DropdownMenu>
                </Fragment>
              )}
            </Flex>
          </StyledDialogEnvironmentWrapper>

          <PostCreationProvider>
            {step === 1 && (
              <Fragment>
                <StyledDialogBodyWrapper flex="1">
                  <Flex height="100%">
                    <EntitySelectorWrapper
                      user={user}
                      entities={entities}
                      selectedProfiles={selectedProfilesForEntitySelector}
                      selectedEntities={selectedEntities}
                      handleSaveSelectedEntities={handleSaveSelectedEntities}
                      handleSaveSelectedProfiles={handleSaveSelectedProfiles}
                      accessLevels={[ACCESS_MANAGE]}
                      permission={PERMISSION_PUBLISH}
                      entitySelectorKey={entitySelectorKey}
                      blockedSocialProfileChecker={({ profile }) => {
                        const { network: { code, display } = {}, status, id } = profile

                        let blocked = false
                        let blockedProfileHoverText = ''

                        if (BLOCKED_PUBLISHING_SOCIAL_PROFILES.includes(code) || status === PROFILE_STATUSES.BLOCKED) {
                          blocked = true
                          blockedProfileHoverText = `${display} is not available for publishing`
                        }

                        if (props.blockedProfilesForSelectedPost) {
                          const foundBlockedProfile = props.blockedProfilesForSelectedPost.find(
                            ({ profile_gid }) => profile_gid === id
                          )

                          if (foundBlockedProfile) {
                            blocked = true

                            const { status: post_status } = foundBlockedProfile

                            if (post_status === PUBLISHED) {
                              blockedProfileHoverText = `Post for ${display} has already been published. It can no longer be edited.`
                            } else if (post_status === FAILED) {
                              blockedProfileHoverText = `Post for ${display} has failed. It can no longer be edited.`
                            } else if (post_status === PROCESSING) {
                              blockedProfileHoverText = `Post for ${display} is being published. It can no longer be edited.`
                            }
                          }
                        }

                        return { blocked, blockedProfileHoverText }
                      }}
                    />
                  </Flex>
                  <Flex width="100%" height="100%">
                    <PostCreationComponent
                      key={postCreationComponentKey}
                      user={user}
                      selectedProfiles={selectedProfiles}
                      selectedEntities={selectedEntities}
                      postForEdit={savedPost}
                      dataForCustomizations={dataForCustomizations}
                      ref={postCreationComponentRef}
                      checkForErrors={checkForErrors}
                      onChangeUpdatePost={onChangeUpdatePost}
                      uploadingMediasAmount={uploadingMediasAmount}
                      setUploadingMediasAmount={setUploadingMediasAmount}
                      confirm={confirm}
                      postCreationComponentDataLoadingCallback={({ isLoading }) => {
                        if (isLoading !== postCreationComponentDataIsLoading) {
                          setPostCreationComponentDataIsLoading(isLoading)
                        }
                      }}
                      isAdvocacy={isAdvocacy}
                      whenToPublish={whenToPublish}
                    />
                  </Flex>
                </StyledDialogBodyWrapper>

                <StyledDialogEnvironmentWrapper
                  p="m"
                  justifyContent={{ mobile: 'flex-end', desktopWide: 'space-between' }}
                  alignItems="center"
                  $isBottom
                  $isAdvocacy={isAdvocacy}
                >
                  <Flex alignItems="center" display={{ mobile: 'none', desktopWide: 'flex' }} maxWidth="700px">
                    <Flex alignItems="center" minWidth="160px">
                      <Text fontSize="m">{selectedProfiles.length ? 'Selected profiles' : 'No profiles selected'}</Text>
                    </Flex>

                    <Box width={{ mobile: '370px', desktopLarge: '440px' }}>
                      <SelectedProfilesSlider
                        selectedProfiles={selectedProfiles}
                        handleClickRemoveProfile={handleClickRemoveProfile}
                      />
                    </Box>
                  </Flex>
                  {postCreationComponentDataIsLoading ? (
                    <Flex alignItems="center" className="cliploader-wrapper">
                      <Text fontSize="xs" mr="s">
                        Loading
                      </Text>
                      <SyncLoader size="3" />
                    </Flex>
                  ) : (
                    <Fragment>
                      {props.postForEdit && props.postForEdit.advocacy_gid ? (
                        <Flex alignItems="center" justifyContent="flex-end">
                          <ButtonWithLoading
                            isSmall
                            onClick={() => {
                              if (!isStepClicked || !isPostSubmitting) {
                                handleClickNextStep({
                                  whenToPublishSpecial: PUBLISH_SAVE_CHANGES,
                                  loadingButton: LOADING_BUTTON_SAVE,
                                })
                              }
                            }}
                            isLoading={isPostSubmitting && loadingButton === LOADING_BUTTON_SAVE}
                          >
                            {isPostSubmitting && loadingButton === LOADING_BUTTON_SAVE
                              ? 'Saving'
                              : `${props.postForEdit.publication_group_id ? 'Save changes' : 'Create advocacy post'}`}
                          </ButtonWithLoading>
                        </Flex>
                      ) : (
                        <Fragment>
                          {props.postForEdit && props.postForEdit.publication_group_id ? (
                            <Flex alignItems="center" justifyContent="flex-end">
                              <ButtonWithLoading
                                buttonComp={Button.Gray}
                                loaderColor={COLOR_CONSTANTS.SALUTE}
                                isSmall
                                onClick={() => {
                                  if (!isStepClicked || !isPostSubmitting) {
                                    handleClickNextStep({
                                      whenToPublishSpecial: PUBLISH_SAVE_CHANGES,
                                      loadingButton: LOADING_BUTTON_SAVE,
                                    })
                                  }
                                }}
                                isLoading={isPostSubmitting && loadingButton === LOADING_BUTTON_SAVE}
                              >
                                {isPostSubmitting && loadingButton === LOADING_BUTTON_SAVE ? 'Saving' : 'Save changes'}
                              </ButtonWithLoading>

                              <StyledScheduleWrapper ml="m">
                                <StyledScheduleButton
                                  isSmall
                                  onClick={() => {
                                    if (!isStepClicked || !isPostSubmitting) {
                                      handleClickNextStep({ whenToPublishSpecial: PUBLISH_LATER })
                                    }
                                  }}
                                  className="schedule-button"
                                  isLoading={
                                    (isPostSubmitting && loadingButton === LOADING_BUTTON_SUBMIT) || isStepClicked
                                  }
                                >
                                  {isPostSubmitting && loadingButton === LOADING_BUTTON_SUBMIT
                                    ? `Saving`
                                    : `${isStepClicked ? 'Processing' : 'Continue'}`}
                                </StyledScheduleButton>

                                <Flex height={pxToRem(30)} width="2px" bg="white" className="schedule-button-divider" />
                                <DropdownMenu
                                  zIndex={16}
                                  top={props.postForEdit && props.postForEdit.status === DRAFT ? '-224px' : '-254px'}
                                  WrapperComp={
                                    <StyledScheduleDropDownButton isSmall className="schedule-button">
                                      <Flex alignItems="center">
                                        <StyledIconArrowDropdown />
                                      </Flex>
                                    </StyledScheduleDropDownButton>
                                  }
                                  isDismissedOnClickInside
                                  isTriangleVisible={false}
                                >
                                  <DropdownMenu.Item
                                    labelMaxWidth="230px"
                                    label="Continue & publish now"
                                    labelFontWeight="medium"
                                    onClick={() => {
                                      if (!isStepClicked || !isPostSubmitting) {
                                        handleClickNextStep({ whenToPublishSpecial: PUBLISH_NOW })
                                      }
                                    }}
                                    labelDescription=""
                                    isCursorPointer
                                  />
                                  <DropdownMenu.Item
                                    labelMaxWidth="230px"
                                    label="Continue & edit schedule"
                                    labelFontWeight="medium"
                                    labelDescription=""
                                    onClick={() => {
                                      if (!isStepClicked || !isPostSubmitting) {
                                        handleClickNextStep({ whenToPublishSpecial: PUBLISH_LATER })
                                      }
                                    }}
                                    isCursorPointer
                                  />
                                  <DropdownMenu.Item
                                    labelMaxWidth="230px"
                                    label="Continue & queue"
                                    labelFontWeight="medium"
                                    labelDescription=""
                                    onClick={() => {
                                      if (!isStepClicked || !isPostSubmitting) {
                                        handleClickNextStep({ whenToPublishSpecial: PUBLISH_QUEUE })
                                      }
                                    }}
                                    isCursorPointer
                                  />

                                  <Divider height="1px" />

                                  {props.postForEdit && props.postForEdit.status !== DRAFT && (
                                    <DropdownMenu.Item
                                      labelMaxWidth="230px"
                                      label="Save as draft"
                                      labelFontWeight="medium"
                                      labelDescription=""
                                      onClick={() => {
                                        if (!isStepClicked || !isPostSubmitting) {
                                          handleClickNextStep({ whenToPublishSpecial: PUBLISH_DRAFT })
                                        }
                                      }}
                                      isCursorPointer
                                    />
                                  )}

                                  <DropdownMenu.Item
                                    labelMaxWidth="230px"
                                    label="Save changes"
                                    labelFontWeight="medium"
                                    onClick={() => {
                                      if (!isStepClicked || !isPostSubmitting) {
                                        handleClickNextStep({ whenToPublishSpecial: PUBLISH_SAVE_CHANGES })
                                      }
                                    }}
                                    labelDescription=""
                                    isCursorPointer
                                  />

                                  {handleClickDuplicatePost && (
                                    <DropdownMenu.Item
                                      labelMaxWidth="230px"
                                      label="Duplicate post"
                                      labelFontWeight="medium"
                                      labelDescription=""
                                      onClick={() => {
                                        if (!isStepClicked || !isPostSubmitting) {
                                          handleClickDuplicatePost()
                                        }
                                      }}
                                      isCursorPointer
                                    />
                                  )}

                                  {handleClickOpenDeletePostModal && (
                                    <DropdownMenu.Item
                                      labelMaxWidth="230px"
                                      label="Remove post"
                                      labelFontWeight="medium"
                                      labelDescription=""
                                      onClick={() => {
                                        if (!isStepClicked || !isPostSubmitting) {
                                          handleClickOpenDeletePostModal()
                                        }
                                      }}
                                      isCursorPointer
                                    />
                                  )}
                                </DropdownMenu>
                              </StyledScheduleWrapper>
                            </Flex>
                          ) : (
                            <Flex alignItems="center" justifyContent="flex-end">
                              {PUBLISHING_TIME.map(({ name, id }) => {
                                if (id === PUBLISH_DRAFT && hasWorkflow) {
                                  return <Fragment key={id} />
                                }

                                if (user.type === USER_TYPES.ADVOCATE && id !== PUBLISH_LATER && id !== PUBLISH_NOW) {
                                  return <Fragment key={id} />
                                }

                                return (
                                  <Flex key={id} alignItems="center" mr="m">
                                    <Radio
                                      id={id}
                                      name="publishTime"
                                      onChange={({ target: { id } }) => {
                                        if (id === PUBLISH_QUEUE) {
                                          if (featuresEnabled[FEATURE_ADD_TO_QUEUE].enabled) {
                                            setWhenToPublish(id)
                                          } else {
                                            Alert.error(featuresEnabled[FEATURE_ADD_TO_QUEUE].description, {
                                              timeout: 10000,
                                            })
                                          }
                                        } else {
                                          setWhenToPublish(id)
                                        }
                                      }}
                                      value={whenToPublish}
                                      checked={whenToPublish === id}
                                    />
                                    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                                    <label htmlFor={id} id={`publish-modal-schedule-time-option-${id}`}>
                                      <Text ml="m" pl="xs">
                                        {name}
                                      </Text>
                                    </label>
                                  </Flex>
                                )
                              })}
                              <ButtonWithLoading
                                mr="m"
                                isSmall
                                onClick={handleClickNextStep}
                                isLoading={whenToPublish === PUBLISH_DRAFT ? isPostSubmitting : isStepClicked}
                              >
                                {whenToPublish === PUBLISH_DRAFT
                                  ? `${isPostSubmitting ? `Saving` : `Save`}`
                                  : `${isStepClicked ? 'Processing' : 'Next'}`}
                              </ButtonWithLoading>
                            </Flex>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </StyledDialogEnvironmentWrapper>
              </Fragment>
            )}
            {step === 2 && (
              <Fragment>
                {hasWorkflow &&
                  (!savedPost.publication_group_id ||
                    props.postForEdit.draft ||
                    (props.postForEdit.status === APPROVED && approversData.is_approver)) && (
                    <MessageWrapper alignItems="center" px="m" py="s">
                      <Flex alignItems="center" justifyContent="space-between" width="100%">
                        <Text fontWeight="bold">This post is assigned to {reviewerName} for review</Text>
                        <Flex alignItems="center" justifyContent="flex-end">
                          <Button.Gray isSmall onClick={handleClickOpenAssignModal}>
                            Change
                          </Button.Gray>
                          <Button.Gray isSmall ml="m" onClick={() => handleClickSaveWorkflow([])}>
                            Cancel
                          </Button.Gray>
                        </Flex>
                      </Flex>
                    </MessageWrapper>
                  )}
                <StyledDialogBodyWrapper flex="1">
                  <Box pl="m" width={{ mobile: '100%', desktop: '460px' }} height="100%">
                    <Scrollbars>
                      <Box minWidth="420px" pr="s">
                        {whenToPublish === PUBLISH_LATER && (
                          <Fragment>
                            {entityPublishValuesGrouped.isMultipleEntityGroups && (
                              <Flex mt="xs" justifyContent="space-between" width="100%" alignItems="center">
                                {entityPublishValuesGrouped.isTogether ? (
                                  <Text>
                                    <Text as="span" fontSize="m">
                                      Multiple content groups:
                                    </Text>{' '}
                                    {selectedTimezone ? `${selectedTimezone.replace(/_/g, ' ')}` : ''}
                                  </Text>
                                ) : (
                                  <Text>
                                    <Text as="span" fontSize="m">
                                      Select time for {entityPublishValues.length} content groups:
                                    </Text>
                                  </Text>
                                )}
                                <DropdownMenu
                                  WrapperComp={
                                    <StyledRoundedButton>
                                      <StyledImage>
                                        <Icon.More stroke="icon_color_gray" />
                                      </StyledImage>
                                    </StyledRoundedButton>
                                  }
                                  isDismissedOnClickInside
                                >
                                  <DropdownMenu.Item
                                    label={`${
                                      entityPublishValuesGrouped.isTogether
                                        ? 'Schedule groups individually'
                                        : 'Schedule groups together'
                                    }`}
                                    onClick={() => {
                                      entityPublishValuesGrouped.isTogether = !entityPublishValuesGrouped.isTogether
                                      setEntityPublishValuesGrouped({ ...entityPublishValuesGrouped })
                                    }}
                                    labelMaxWidth="200px"
                                    isCursorPointer
                                  />
                                </DropdownMenu>
                              </Flex>
                            )}

                            {entityPublishValuesGrouped.isMultipleEntityGroups && (
                              <StyledScheduleIndividuallyText
                                onClick={() => {
                                  entityPublishValuesGrouped.isTogether = !entityPublishValuesGrouped.isTogether
                                  setEntityPublishValuesGrouped({ ...entityPublishValuesGrouped })
                                }}
                              >
                                {entityPublishValuesGrouped.isTogether
                                  ? 'Schedule groups individually'
                                  : 'Schedule groups together'}
                              </StyledScheduleIndividuallyText>
                            )}

                            {entityPublishValuesGrouped.isTogether ? (
                              <Flex flexDirection="column">
                                <Flex my="s">
                                  {entityPublishValuesGrouped.profiles.map(
                                    ({
                                      id,
                                      picture_url,
                                      custom_network_picture_url,
                                      profile_url,
                                      username,
                                      display,
                                      code,
                                      name,
                                      profile_id,
                                      type,
                                    }) => {
                                      return (
                                        <Flex alignItems="center" key={id} mr="m">
                                          <Flex alignItems="center">
                                            <Flex
                                              alignItems="center"
                                              onMouseEnter={(e) => {
                                                tooltipRef.current.handleShowTooltip({
                                                  contentComp: (
                                                    <ProfileItemTooltip
                                                      profile={{
                                                        code,
                                                        picture_url,
                                                        profile_url,
                                                        name,
                                                        username,
                                                        display,
                                                        profile_id,
                                                        type,
                                                        custom_network_picture_url,
                                                      }}
                                                    />
                                                  ),
                                                  wrapperComp: e.currentTarget,
                                                })
                                              }}
                                              onMouseLeave={() => {
                                                tooltipRef.current.handleHideTooltip()
                                              }}
                                            >
                                              <SocialProfileImage
                                                picture_url={picture_url}
                                                code={code}
                                                custom_network_picture_url={custom_network_picture_url}
                                              />
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                      )
                                    }
                                  )}
                                </Flex>
                                <DatePicker
                                  publishTimeData={entityPublishValues[0].publishTimeData}
                                  handleChangeDate={({ publishTimeData }) => {
                                    entityPublishValues.forEach((item) => {
                                      item.publishTimeData = JSON.parse(JSON.stringify(publishTimeData))
                                      item.profiles.forEach((profile) => {
                                        profile.publishTimeData = JSON.parse(JSON.stringify(publishTimeData))
                                      })
                                    })
                                    setEntityPublishValues([...entityPublishValues])
                                    handleUpdateCalendarEventsForPublish({
                                      eventsForPublish: entityPublishValues,
                                    })
                                  }}
                                  timezone={selectedTimezone}
                                  selectedProfiles={entityPublishValues
                                    .map(({ profiles }) => profiles)
                                    .reduce((one, two) => [...one.map((item) => item), ...two.map((item) => item)], [])}
                                  combined
                                />
                              </Flex>
                            ) : (
                              <Flex flexDirection="column">
                                {entityPublishValues.map(
                                  (
                                    { name, entityType, timezone, profiles, scheduleIndividually, publishTimeData },
                                    index
                                  ) => (
                                    <StyledFlex flexDirection="column" key={index} mt="m" pb="s">
                                      <Flex justifyContent="space-between" width="100%" alignItems="center">
                                        <Text>
                                          <Text as="span" fontSize="m">
                                            {name}
                                          </Text>{' '}
                                          ({entityType}){timezone ? ` - ${timezone.replace(/_/g, ' ')}` : ''}
                                        </Text>
                                        {profiles && profiles.length > 1 && (
                                          <DropdownMenu
                                            WrapperComp={
                                              <StyledImage>
                                                <Icon.More stroke="icon_color_gray" />
                                              </StyledImage>
                                            }
                                            isDismissedOnClickInside
                                          >
                                            <DropdownMenu.Item
                                              label={`${
                                                scheduleIndividually
                                                  ? 'Schedule profiles together'
                                                  : 'Schedule profiles individually'
                                              }`}
                                              onClick={() => {
                                                entityPublishValues[index].scheduleIndividually = !scheduleIndividually
                                                setEntityPublishValues([...entityPublishValues])
                                                handleUpdateCalendarEventsForPublish({
                                                  eventsForPublish: entityPublishValues,
                                                })
                                              }}
                                              labelMaxWidth="200px"
                                              isCursorPointer
                                            />
                                          </DropdownMenu>
                                        )}
                                      </Flex>

                                      {profiles && profiles.length > 1 && (
                                        <StyledScheduleIndividuallyText
                                          onClick={() => {
                                            entityPublishValues[index].scheduleIndividually = !scheduleIndividually
                                            setEntityPublishValues([...entityPublishValues])
                                            handleUpdateCalendarEventsForPublish({
                                              eventsForPublish: entityPublishValues,
                                            })
                                          }}
                                        >
                                          {scheduleIndividually
                                            ? 'Schedule profiles together'
                                            : 'Schedule profiles individually'}
                                        </StyledScheduleIndividuallyText>
                                      )}

                                      {!scheduleIndividually ? (
                                        <Flex flexDirection="column">
                                          <Flex my="s">
                                            {profiles
                                              .slice(0, MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS + 1)
                                              .map(
                                                ({
                                                  id,
                                                  picture_url,
                                                  custom_network_picture_url,
                                                  profile_url,
                                                  username,
                                                  display,
                                                  code,
                                                  profile_id,
                                                  type,
                                                }) => {
                                                  return (
                                                    <Flex alignItems="center" key={id} mr="m">
                                                      <Flex alignItems="center">
                                                        <Flex
                                                          alignItems="center"
                                                          onMouseEnter={(e) => {
                                                            tooltipRef.current.handleShowTooltip({
                                                              contentComp: (
                                                                <ProfileItemTooltip
                                                                  profile={{
                                                                    code,
                                                                    picture_url,
                                                                    profile_url,
                                                                    name,
                                                                    username,
                                                                    display,
                                                                    profile_id,
                                                                    type,
                                                                    custom_network_picture_url,
                                                                  }}
                                                                />
                                                              ),
                                                              wrapperComp: e.currentTarget,
                                                            })
                                                          }}
                                                          onMouseLeave={() => {
                                                            tooltipRef.current.handleHideTooltip()
                                                          }}
                                                        >
                                                          <SocialProfileImage
                                                            picture_url={picture_url}
                                                            code={code}
                                                            custom_network_picture_url={custom_network_picture_url}
                                                          />
                                                        </Flex>
                                                      </Flex>
                                                    </Flex>
                                                  )
                                                }
                                              )}
                                          </Flex>
                                          <DatePicker
                                            publishTimeData={publishTimeData}
                                            handleChangeDate={({ publishTimeData }) => {
                                              entityPublishValues[index].publishTimeData = JSON.parse(
                                                JSON.stringify(publishTimeData)
                                              )
                                              entityPublishValues[index].profiles.forEach((profile) => {
                                                profile.publishTimeData = JSON.parse(JSON.stringify(publishTimeData))
                                              })
                                              setEntityPublishValues([...entityPublishValues])
                                              handleUpdateCalendarEventsForPublish({
                                                eventsForPublish: entityPublishValues,
                                              })
                                            }}
                                            selectedProfiles={profiles}
                                            timezone={timezone}
                                            combined
                                          />
                                        </Flex>
                                      ) : (
                                        <Flex flexDirection="column" mt="s">
                                          {profiles.map((item, childIndex) => (
                                            <StyledFlex flexDirection="column" key={item.id} mb="m" pb="s">
                                              <Flex alignItems="center" mb="s">
                                                <Flex alignItems="center">
                                                  <Flex
                                                    alignItems="center"
                                                    onMouseEnter={(e) => {
                                                      tooltipRef.current.handleShowTooltip({
                                                        contentComp: <ProfileItemTooltip profile={item} />,
                                                        wrapperComp: e.currentTarget,
                                                      })
                                                    }}
                                                    onMouseLeave={() => {
                                                      tooltipRef.current.handleHideTooltip()
                                                    }}
                                                  >
                                                    <SocialProfileImage
                                                      picture_url={item.picture_url}
                                                      code={item.code}
                                                      custom_network_picture_url={item.custom_network_picture_url}
                                                    />
                                                    <Text ml="s">{item.name}</Text>
                                                  </Flex>
                                                </Flex>
                                              </Flex>
                                              <DatePicker
                                                publishTimeData={item.publishTimeData}
                                                handleChangeDate={({ publishTimeData }) => {
                                                  entityPublishValues[index].profiles[
                                                    childIndex
                                                  ].publishTimeData = publishTimeData
                                                  setEntityPublishValues([...entityPublishValues])
                                                  handleUpdateCalendarEventsForPublish({
                                                    eventsForPublish: entityPublishValues,
                                                  })
                                                }}
                                                timezone={timezone}
                                                selectedProfiles={[item]}
                                                combined={false}
                                              />
                                            </StyledFlex>
                                          ))}
                                        </Flex>
                                      )}
                                    </StyledFlex>
                                  )
                                )}
                              </Flex>
                            )}
                          </Fragment>
                        )}
                        {whenToPublish === PUBLISH_QUEUE && (
                          <Flex flexDirection="column" justifyContent="center">
                            <Text fontSize="m" mt="m">
                              Add this post to your content queue
                            </Text>
                            <Text color="secondaryText">
                              'Queue next' will add this post to the front of your queue to be published next. 'Queue
                              last' will add this post to the end of your queue to be published last. To view and change
                              your publishing queue please go to{' '}
                              <Link as="a" href="/settings/publishing-settings" color="primary" target="_blank">
                                Publishing Settings
                              </Link>
                              .
                            </Text>
                            <StyledFlex flexDirection="column" mt="s" pb="s">
                              <Flex alignItems="center">
                                {QUEUE_TIME.map(({ name, id }) => (
                                  <Flex key={id} alignItems="center" mr="m">
                                    <Radio
                                      id={id}
                                      name="publishQueueTime"
                                      onChange={({ target: { id } }) => {
                                        if (!isGettingQueueData) {
                                          setWhenToQueue(id)
                                          getQueueData({ whenToQueueTemp: id })
                                        }
                                      }}
                                      value={whenToQueue}
                                      checked={whenToQueue === id}
                                      disabled={isGettingQueueData}
                                    />
                                    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                                    <label htmlFor={id}>
                                      <Text ml="m" pl="xs" mt="-1px" fontSize="s">
                                        {name}
                                      </Text>
                                    </label>
                                  </Flex>
                                ))}
                              </Flex>
                              {usedQueueLabels.length > 1 && (
                                <Flex alignItems="center" my="s">
                                  <Text fontSize="s">based on</Text>

                                  <Flex flexDirection="column" width="200px" ml="s">
                                    <DropDown
                                      onChange={(option) => {
                                        if (!isGettingQueueData) {
                                          if (featuresEnabled[FEATURE_QUEUE_LABELS].enabled) {
                                            setSelectedQueueLabel({ ...option })
                                          } else {
                                            Alert.error(featuresEnabled[FEATURE_QUEUE_LABELS].description, {
                                              timeout: 5000,
                                            })
                                          }
                                        }
                                      }}
                                      value={
                                        selectedQueueLabel
                                          ? usedQueueLabels.find(({ value }) => value === selectedQueueLabel.value) ||
                                            DEFAULT_QUEUE_LABEL
                                          : DEFAULT_QUEUE_LABEL
                                      }
                                      options={usedQueueLabels}
                                      width="100%"
                                    />
                                  </Flex>
                                </Flex>
                              )}
                            </StyledFlex>
                            <Flex flexDirection="column">
                              {entityPublishValues.map(({ timezone, profiles }, index) => (
                                <StyledFlex flexDirection="column" key={index} mt="m" pb="s">
                                  <Flex flexDirection="column" mt="s">
                                    {profiles.map((item, childIndex) => (
                                      <StyledFlex flexDirection="column" key={item.id} mb="m" pb="s">
                                        <Flex justifyContent="space-between" width="100%" alignItems="center" mb="s">
                                          <Flex
                                            alignItems="center"
                                            onMouseEnter={(e) => {
                                              tooltipRef.current.handleShowTooltip({
                                                contentComp: <ProfileItemTooltip profile={item} />,
                                                wrapperComp: e.currentTarget,
                                              })
                                            }}
                                            onMouseLeave={() => {
                                              tooltipRef.current.handleHideTooltip()
                                            }}
                                          >
                                            <SocialProfileImage
                                              picture_url={item.picture_url}
                                              code={item.code}
                                              custom_network_picture_url={item.custom_network_picture_url}
                                            />
                                            <Text ml="s">
                                              {item.name} {timezone ? ` - ${timezone.replace(/_/g, ' ')}` : ''}
                                            </Text>
                                          </Flex>
                                          <Flex
                                            alignItems="center"
                                            onClick={() => {
                                              if (item.posts_in_queue !== 0) {
                                                const data = {
                                                  eventIds: [],
                                                  specialEvents: [],
                                                }
                                                item.posts
                                                  .filter(({ in_queue }) => in_queue)
                                                  .forEach(({ publication_gid }) => {
                                                    data.eventIds.push(publication_gid)
                                                  })

                                                setSliderPostsPreviewData(data)
                                              }
                                            }}
                                          >
                                            {!item.changePublishTimeFromQueueToSchedule && (
                                              <StyledQueueText color="secondaryText" mr="s">
                                                {`${item.posts_in_queue} ${
                                                  item.posts_in_queue === 1 ? 'post' : 'posts'
                                                } in queue`}
                                              </StyledQueueText>
                                            )}
                                            <DropdownMenu
                                              WrapperComp={
                                                <StyledImage>
                                                  <Icon.More stroke="icon_color_gray" />
                                                </StyledImage>
                                              }
                                              isDismissedOnClickInside
                                            >
                                              <DropdownMenu.Item
                                                label={
                                                  item.changePublishTimeFromQueueToSchedule
                                                    ? `${whenToQueue.charAt(0).toUpperCase()}${whenToQueue
                                                        .slice(1)
                                                        .replace(/_/g, ' ')}`
                                                    : 'Change schedule time'
                                                }
                                                onClick={() => {
                                                  entityPublishValues[index].profiles[
                                                    childIndex
                                                  ].changePublishTimeFromQueueToSchedule = !item.changePublishTimeFromQueueToSchedule
                                                  if (item.changePublishTimeFromQueueToSchedule) {
                                                    entityPublishValues[index].profiles[childIndex].publishTimeData =
                                                      item.savedPublishTimeData
                                                  }
                                                  setEntityPublishValues([...entityPublishValues])
                                                  handleUpdateCalendarEventsForPublish({
                                                    eventsForPublish: entityPublishValues,
                                                  })
                                                }}
                                                isCursorPointer
                                              />
                                            </DropdownMenu>
                                          </Flex>
                                        </Flex>
                                        {item.changePublishTimeFromQueueToSchedule ? (
                                          <DatePicker
                                            publishTimeData={item.publishTimeData}
                                            handleChangeDate={({ publishTimeData }) => {
                                              entityPublishValues[index].profiles[
                                                childIndex
                                              ].publishTimeData = publishTimeData
                                              setEntityPublishValues([...entityPublishValues])
                                              handleUpdateCalendarEventsForPublish({
                                                eventsForPublish: entityPublishValues,
                                              })
                                            }}
                                            timezone={timezone}
                                            selectedProfiles={[item]}
                                            combined={false}
                                          />
                                        ) : (
                                          <Flex flexDirection="column">
                                            <Flex minHeight="40px" height="40px" alignItems="center">
                                              <Text color="secondaryText">
                                                {moment(
                                                  `${item.publishTimeData.date} ${item.publishTimeData.hour}:${item.publishTimeData.minute} ${item.publishTimeData.interval}`
                                                ).format('dddd, MMMM D YYYY, h:mm A')}
                                              </Text>
                                            </Flex>

                                            {item.queue_labels && item.queue_labels.length > 0 && (
                                              <Flex flexWrap="wrap">
                                                {item.queue_labels.map((label) => (
                                                  <Badge.Status key={label} mr="s" mt="xs">
                                                    <Text fontSize="xs" textAlign="center">
                                                      {label}
                                                    </Text>
                                                  </Badge.Status>
                                                ))}
                                              </Flex>
                                            )}
                                          </Flex>
                                        )}
                                      </StyledFlex>
                                    ))}
                                  </Flex>
                                </StyledFlex>
                              ))}
                            </Flex>
                          </Flex>
                        )}
                        {whenToPublish === PUBLISH_NOW && (
                          <Flex flexDirection="column" justifyContent="center" alignItems="center">
                            <Text fontSize="m" mt="m" textAlign="center">
                              Your content
                              <br />
                              <Text as="span" fontWeight="bold">
                                will be published now
                              </Text>
                            </Text>
                          </Flex>
                        )}
                        {whenToPublish === PUBLISH_WHEN_READY && (
                          <Flex flexDirection="column" justifyContent="center" alignItems="center">
                            <Text fontSize="m" mt="m" textAlign="center">
                              Your content
                              <br />
                              <Text as="span" fontWeight="bold">
                                will be published when media is processed.
                              </Text>
                            </Text>
                          </Flex>
                        )}
                      </Box>
                    </Scrollbars>
                  </Box>
                  <CalendarColumnWrapper
                    display={{ mobile: 'none', desktop: 'flex' }}
                    flexDirection="column"
                    width="calc(100% - 460px)"
                    height="100%"
                    position="relative"
                  >
                    <StyledProgressWrapper
                      id="publish-modal-progress-wrapper"
                      alignItems="center"
                      justifyContent="center"
                      display="none"
                      className="cliploader-wrapper"
                    >
                      <ClipLoader size="100" />
                    </StyledProgressWrapper>

                    <Flex alignItems="center" justifyContent="space-between" px="m" flexWrap="wrap">
                      <Flex alignItems="center" width="330px">
                        <Text as="span">{formatTimezoneToGMT(selectedTimezone).long}</Text>
                      </Flex>
                      <Flex alignItems="center" justifyContent="center" my="s">
                        <Box mx="m" width="300px" mt="3px">
                          <SelectedProfilesSlider
                            selectedProfiles={selectedProfiles}
                            handleClickRemoveProfile={handleClickRemoveProfile}
                          />
                        </Box>
                      </Flex>
                      <Flex alignItems="center">
                        <StyledDateNavigationButton
                          onClick={() => handleChangeCalendarViewDates('previous')}
                          height={DEFAULT_MENU_HEIGHT}
                        >
                          <Icon.VistaSocialDashboardArrow width="16px" height="16px" color="primaryText" />
                        </StyledDateNavigationButton>
                        <Flex minWidth={{ mobile: 'auto', desktopWide: '110px' }} justifyContent="center" mx="s">
                          <Text>{calendarOptions.viewTitle}</Text>
                        </Flex>
                        <StyledDateNavigationButton
                          onClick={() => handleChangeCalendarViewDates('next')}
                          height={DEFAULT_MENU_HEIGHT}
                        >
                          <VistaSocialDashboardArrowRight height="16px" width="16px" fill="primaryText" />
                        </StyledDateNavigationButton>
                      </Flex>
                    </Flex>

                    <Box width="100%" height="calc(100% - 58px)">
                      <FullCalendar
                        ref={calendarRef}
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                          left: '',
                          center: '',
                          right: '',
                        }}
                        editable={false}
                        events={renderEvents()}
                        selectable
                        select={handleSelectDay}
                        fixedWeekCount={false}
                        dayHeaderFormat={{ weekday: 'long' }}
                        eventContent={(e) => {
                          return renderEventContent({
                            eventInfo: e,
                            selectedProfiles,
                            setTooltipData: updateTooltipData,
                            selectedTimezone,
                            calendarPostPreview: CALENDAR_POST_PREVIEW,
                          })
                        }}
                        firstDay={CALENDAR_WEEK_DAY_START}
                        dayMaxEventRows
                        views={{ dayGridMonth: { dayMaxEventRows: DAY_MAX_EVENT_ROWS } }}
                        nextDayThreshold="01:00:00"
                        displayEventEnd={false}
                        moreLinkClick={(...props) => {
                          const { 0: { allSegs } = {} } = props

                          const data = {
                            eventIds: [],
                            specialEvents: [],
                          }

                          allSegs.forEach(
                            ({
                              event: { id, title, extendedProps: { type, specialEvents = [] } = {}, extendedProps },
                            }) => {
                              if (type) {
                                if (type === POST_TYPE_HOLIDAY) {
                                  data.specialEvents = [...data.specialEvents, ...specialEvents]
                                } else if (
                                  type === POST_TYPE_SOCIAL_POST ||
                                  type === POST_TYPE_EXTERNAL_CALENDAR_EVENT ||
                                  type === POST_TYPE_NOTE
                                ) {
                                  data.specialEvents.push({ id, title, ...extendedProps })
                                }
                              } else if (id) {
                                data.eventIds.push(id)
                              }
                            }
                          )

                          setSliderPostsPreviewData(data)
                        }}
                        moreLinkContent={(...props) => {
                          const { 0: { num } = {} } = props
                          return (
                            <Text fontSize="xs" color="primary">
                              All{CALENDAR_POST_PREVIEW === CALENDAR_POST_PREVIEW_CONDENSED ? ' networks ' : ' '}(
                              {num + DAY_MAX_EVENT_ROWS - 1})
                            </Text>
                          )
                        }}
                      />
                    </Box>
                  </CalendarColumnWrapper>
                </StyledDialogBodyWrapper>
                <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
                  <Button.Gray isDisabled={isPostSubmitting} mr="m" isSmall onClick={handleClickPreviousStep}>
                    Previous
                  </Button.Gray>
                  {savedPost.publication_group_id && hasWorkflow && !savedPost.draft ? (
                    <Flex justifyContent="flex-end">
                      <ButtonWithLoading
                        buttonComp={Button.Gray}
                        loaderColor={COLOR_CONSTANTS.SALUTE}
                        isSmall
                        onClick={handleClickSavePost}
                        isLoading={isPostSubmitting && loadingButton === LOADING_BUTTON_SAVE}
                      >
                        {isPostSubmitting && loadingButton === LOADING_BUTTON_SAVE ? 'Saving' : 'Save changes'}
                      </ButtonWithLoading>

                      {approversData.is_approver && (
                        <React.Fragment>
                          {props.postForEdit && props.postForEdit.status === REVIEW && (
                            <ButtonWithLoading
                              buttonComp={Button.Error}
                              loaderColor={COLOR_CONSTANTS.SALUTE}
                              isSmall
                              ml="m"
                              isLoading={isPostSubmitting && loadingButton === LOADING_BUTTON_REJECT}
                              onClick={() => {
                                handleClickOpenRejectApproveNotesModal({ type: 'reject' })
                              }}
                            >
                              {isPostSubmitting && loadingButton === LOADING_BUTTON_REJECT ? 'Rejecting' : 'Reject'}
                            </ButtonWithLoading>
                          )}

                          {props.postForEdit.status === APPROVED ? (
                            <ButtonWithLoading
                              isSmall
                              ml="m"
                              isLoading={isPostSubmitting && loadingButton === LOADING_BUTTON_SUBMIT}
                              onClick={() => {
                                handleClickSendForApprovalPost({ scheduleType: SCHEDULE_TYPE_DEFAULT })
                              }}
                              id="publish-modal-button-send-for-approval"
                            >
                              {isPostSubmitting && loadingButton === LOADING_BUTTON_SUBMIT
                                ? 'Sending'
                                : 'Send for approval'}
                            </ButtonWithLoading>
                          ) : (
                            <ButtonWithLoading
                              buttonComp={
                                props.postForEdit && props.postForEdit.status === REJECTED
                                  ? Button.Gray
                                  : Button.Gradient
                              }
                              loaderColor={
                                props.postForEdit && props.postForEdit.status === REJECTED
                                  ? COLOR_CONSTANTS.SALUTE
                                  : 'white'
                              }
                              isSmall
                              ml="m"
                              isLoading={isPostSubmitting && loadingButton === LOADING_BUTTON_APPROVE}
                              onClick={() => {
                                handleClickOpenRejectApproveNotesModal({ type: 'approve' })
                              }}
                            >
                              Approve and{' '}
                              {whenToPublish === PUBLISH_LATER || whenToPublish === PUBLISH_QUEUE
                                ? 'schedule'
                                : `publish ${whenToPublish === PUBLISH_WHEN_READY ? 'when ready' : 'now'}`}
                            </ButtonWithLoading>
                          )}
                        </React.Fragment>
                      )}

                      {props.postForEdit &&
                        props.postForEdit.status === REJECTED &&
                        (approversData.is_approver || post_creator_user_gid === user.id) && (
                          <ButtonWithLoading
                            isSmall
                            ml="m"
                            isLoading={isPostSubmitting && loadingButton === LOADING_BUTTON_SUBMIT}
                            onClick={() => {
                              handleClickSendForApprovalPost({ scheduleType: SCHEDULE_TYPE_DEFAULT })
                            }}
                          >
                            {isPostSubmitting && loadingButton === LOADING_BUTTON_SUBMIT
                              ? 'Sending'
                              : 'Send for approval'}
                          </ButtonWithLoading>
                        )}
                    </Flex>
                  ) : (
                    <Flex justifyContent="flex-end">
                      {approversData.is_approver && !hasWorkflow && (
                        <Button.Gray isSmall onClick={handleClickOpenAssignModal} id="publish-modal-button-assign">
                          <Flex alignItems="center">
                            <StyledGroupEqualIcon width="22px" height="14px" color="primaryText" />
                            <Text ml="s">Assign</Text>
                          </Flex>
                        </Button.Gray>
                      )}
                      <StyledScheduleWrapper ml="m">
                        <StyledScheduleButton
                          isSmall
                          onClick={() => {
                            if (hasWorkflow || !approversData.is_approver) {
                              handleClickSendForApprovalPost({ scheduleType: SCHEDULE_TYPE_DEFAULT })
                            } else {
                              handleClickSubmitPost({ scheduleType: SCHEDULE_TYPE_DEFAULT })
                            }
                          }}
                          isLoading={isPostSubmitting && loadingButton === LOADING_BUTTON_SUBMIT}
                          className="schedule-button"
                        >
                          {hasWorkflow || !approversData.is_approver ? (
                            <Fragment>
                              {isPostSubmitting && loadingButton === LOADING_BUTTON_SUBMIT
                                ? 'Sending'
                                : 'Send for approval'}
                            </Fragment>
                          ) : (
                            <Fragment>
                              {whenToPublish === PUBLISH_LATER || whenToPublish === PUBLISH_QUEUE
                                ? `${
                                    isPostSubmitting && loadingButton === LOADING_BUTTON_SUBMIT
                                      ? 'Scheduling'
                                      : 'Schedule'
                                  }`
                                : `${
                                    isPostSubmitting && loadingButton === LOADING_BUTTON_SUBMIT
                                      ? 'Publishing'
                                      : `Publish ${whenToPublish === PUBLISH_WHEN_READY ? 'when ready' : 'now'}`
                                  }`}
                            </Fragment>
                          )}
                        </StyledScheduleButton>
                        <Flex height={pxToRem(30)} width="2px" bg="white" className="schedule-button-divider" />
                        <DropdownMenu
                          zIndex={16}
                          top="-165px"
                          WrapperComp={
                            <StyledScheduleDropDownButton isSmall className="schedule-button">
                              <Flex alignItems="center">
                                <StyledIconArrowDropdown />
                              </Flex>
                            </StyledScheduleDropDownButton>
                          }
                          isDismissedOnClickInside
                          isTriangleVisible={false}
                        >
                          <DropdownMenu.Item
                            labelMaxWidth="230px"
                            label={`${
                              hasWorkflow || !approversData.is_approver
                                ? 'Send for approval'
                                : whenToPublish === PUBLISH_LATER || whenToPublish === PUBLISH_QUEUE
                                ? 'Schedule'
                                : `Publish ${whenToPublish === PUBLISH_WHEN_READY ? 'when ready' : 'now'}`
                            } + New`}
                            labelFontWeight="medium"
                            iconName="Checkmark"
                            iconWidth="0px"
                            iconColor="primaryText"
                            onClick={() => {
                              if (!isPostSubmitting) {
                                if (hasWorkflow || !approversData.is_approver) {
                                  handleClickSendForApprovalPost({ scheduleType: SCHEDULE_TYPE_NEW })
                                } else {
                                  handleClickSubmitPost({ scheduleType: SCHEDULE_TYPE_NEW })
                                }
                              }
                            }}
                            labelDescription="Re-open blank Compose window"
                            isCursorPointer
                          />
                          <DropdownMenu.Item
                            labelMaxWidth="230px"
                            iconName="Checkmark"
                            iconWidth="0px"
                            iconColor="primaryText"
                            label={`${
                              hasWorkflow || !approversData.is_approver
                                ? 'Send for approval'
                                : whenToPublish === PUBLISH_LATER || whenToPublish === PUBLISH_QUEUE
                                ? 'Schedule'
                                : `Publish ${whenToPublish === PUBLISH_WHEN_READY ? 'when ready' : 'now'}`
                            } + Duplicate`}
                            labelFontWeight="medium"
                            labelDescription="Re-open to edit for other networks"
                            onClick={() => {
                              if (!isPostSubmitting) {
                                if (hasWorkflow || !approversData.is_approver) {
                                  handleClickSendForApprovalPost({ scheduleType: SCHEDULE_TYPE_DUPLICATE })
                                } else {
                                  handleClickSubmitPost({ scheduleType: SCHEDULE_TYPE_DUPLICATE })
                                }
                              }
                            }}
                            isCursorPointer
                          />
                          <DropdownMenu.Item
                            labelMaxWidth="230px"
                            iconName="Checkmark"
                            iconWidth="10px"
                            iconColor="primaryText"
                            label={`${
                              hasWorkflow || !approversData.is_approver
                                ? 'Send for approval'
                                : whenToPublish === PUBLISH_LATER || whenToPublish === PUBLISH_QUEUE
                                ? 'Schedule'
                                : `Publish ${whenToPublish === PUBLISH_WHEN_READY ? 'when ready' : 'now'}`
                            }`}
                            labelFontWeight="medium"
                            labelDescription="Close compose after sending"
                            onClick={() => {
                              if (!isPostSubmitting) {
                                if (hasWorkflow || !approversData.is_approver) {
                                  handleClickSendForApprovalPost({ scheduleType: SCHEDULE_TYPE_DEFAULT })
                                } else {
                                  handleClickSubmitPost({ scheduleType: SCHEDULE_TYPE_DEFAULT })
                                }
                              }
                            }}
                            isCursorPointer
                          />
                        </DropdownMenu>
                      </StyledScheduleWrapper>
                    </Flex>
                  )}
                </StyledDialogEnvironmentWrapper>
              </Fragment>
            )}

            <SpecialMediaProcessingDataComponent ref={specialMediaProcessingDataComponentRef} />
          </PostCreationProvider>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal} isDisabled={isPostSubmitting}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
        {isAssignModalOpen && (
          <AssignModal
            user={user}
            isOpen={isAssignModalOpen}
            handleDismiss={handleClickCloseAssignModal}
            handleSave={handleClickSaveWorkflow}
            workflow={savedPost.workflow}
            approvers={approversData.approvers || []}
            entityPublishValues={entityPublishValues}
          />
        )}

        {rejectApproveNotesModalOptions.isOpen && (
          <RejectApproveNotesModal
            handleDismiss={handleClickCloseRejectApproveNotesModal}
            isOpen={rejectApproveNotesModalOptions.isOpen}
            handleRejectApprove={handleClickSaveRejectApproveNotes}
            review={review}
            isPostSubmitting={isPostSubmitting}
            type={rejectApproveNotesModalOptions.type}
          />
        )}

        {isSetTimezoneModalOpen && (
          <SetTimezoneModal
            user={user}
            isOpen={isSetTimezoneModalOpen}
            handleDismiss={handleClickCloseSetTimezoneModal}
            entitiesForSettingTimezone={selectedEntities}
            handleUpdateEntities={handleUpdateEntities}
          />
        )}

        <CalendarItemTooltip ref={tooltipRef} />

        <DeletePostModal
          handleDismiss={handleClickCloseDeletePostModal}
          isOpen={isDeletePostModalOpen}
          isPostSubmitting={isPostSubmitting}
          handleDelete={handleClickDeletePost}
        />

        {sliderPostsPreviewData && (
          <SliderPostsPreview
            user={user}
            isOpen={!!sliderPostsPreviewData}
            data={sliderPostsPreviewData || {}}
            setSliderPostsPreviewData={setSliderPostsPreviewData}
            handleClickEvent={() => {}}
            timezone={selectedTimezone}
            handleDeletePost={() => {}}
            isPostDeleting={false}
            handleUpdateCalendarPostLabels={() => {}}
            hasComponentControls={false}
          />
        )}

        {sliderOptions.isOpen && (
          <SliderCommentsPreview
            isOpen={sliderOptions.isOpen}
            data={sliderOptions.data}
            handleDismiss={handleClickCloseComments}
            user={user}
            showProfileGroupSelector
          />
        )}

        {videoProcessingWarningModalOptions.isOpen && (
          <VideoProcessingWarningModal
            handleDismiss={() => {
              setVideoProcessingWarningModalOptions({ ...DEFAULT_VIDEO_PROCESSING_MODAL_OPTIONS })
            }}
            videos={videoProcessingWarningModalOptions.data}
            isOpen={videoProcessingWarningModalOptions.isOpen}
            handleClickNextStep={({ publish_at }) => {
              setWhenToPublish(publish_at)
              setClickedNextStepFromVideoProcessingWarningModal(true)
            }}
          />
        )}

        {sendForApprovalProfileGroupsModal.isOpen && (
          <SendForApprovalProfileGroupsModal
            handleDismiss={handleClickCloseSendForApprovalProfileGroupsModal}
            selectedEntities={sendForApprovalProfileGroupsModal.selectedEntities}
            isOpen={sendForApprovalProfileGroupsModal.isOpen}
            callbackFunc={sendForApprovalProfileGroupsModal.callbackFunc}
          />
        )}

        {isHistoryPostModalOpen && (
          <HistoryPostModal
            handleClickCloseModal={handleClickCloseHistoryModal}
            timezone={savedPost.timezone}
            post={savedPost}
            isOpen={isHistoryPostModalOpen}
          />
        )}
      </Box>
    </StyledDialogOverlay>
  )
}
PublishModal.defaultProps = {
  selectedEntities: [],
  entities: [],
  selectedProfiles: [],
  blockedProfilesForSelectedPost: null,
  selectedCalendarDate: null,
  dataForCustomizations: {},
  postForEdit: null,
  handleUpdateEntities: () => {},
  isPostSubmitting: false,
  loadingButton: null,
}

PublishModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  entities: PropTypes.array,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  blockedProfilesForSelectedPost: PropTypes.array,
  handleSubmitPost: PropTypes.func.isRequired,
  isPostSubmitting: PropTypes.bool,
  loadingButton: PropTypes.string,
  selectedCalendarDate: PropTypes.string,
  getCalendarEvents: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  dataForCustomizations: PropTypes.object,
  postForEdit: PropTypes.object,
  handleUpdateEntities: PropTypes.func,
  calendarPostPreview: PropTypes.string.isRequired,
  calendarFirstDay: PropTypes.number.isRequired,
  handleDeletePost: PropTypes.func.isRequired,
  handleApproveRejectReminderPost: PropTypes.func.isRequired,
}

PublishModal.displayName = 'PublishModal'

export default withConfirm(PublishModal)
